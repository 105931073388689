/* tslint:disable */
/* eslint-disable */
/**
 * Online Incasso
 * The OI API description
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Activities
 */
export interface Activities {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof Activities
     */
    'activity'?: Array<Activity> | null;
}
/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'activity_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'activity_description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformation
 */
export interface AdditionalInformation {
    /**
     * 
     * @type {AdditionalInformationMisc}
     * @memberof AdditionalInformation
     */
    'misc'?: AdditionalInformationMisc | null;
    /**
     * 
     * @type {AdditionalInformationIndustryQuartileAnalysis}
     * @memberof AdditionalInformation
     */
    'industry_quartile_analysis'?: AdditionalInformationIndustryQuartileAnalysis | null;
    /**
     * 
     * @type {AdditionalInformationIndustryComparison}
     * @memberof AdditionalInformation
     */
    'industry_comparison'?: AdditionalInformationIndustryComparison | null;
    /**
     * 
     * @type {AdditionalInformationPaymentExpectationsSummary}
     * @memberof AdditionalInformation
     */
    'payment_expectations_summary'?: AdditionalInformationPaymentExpectationsSummary | null;
    /**
     * 
     * @type {AdditionalInformationExtendedGroupStructure}
     * @memberof AdditionalInformation
     */
    'extended_group_structure'?: AdditionalInformationExtendedGroupStructure | null;
    /**
     * 
     * @type {AdditionalInformationAdditionalFinancials}
     * @memberof AdditionalInformation
     */
    'additional_financials'?: AdditionalInformationAdditionalFinancials | null;
    /**
     * 
     * @type {AdditionalInformationCompanyHistory}
     * @memberof AdditionalInformation
     */
    'company_history'?: AdditionalInformationCompanyHistory | null;
    /**
     * 
     * @type {AdditionalInformationKvkFilings}
     * @memberof AdditionalInformation
     */
    'kvk_filings'?: AdditionalInformationKvkFilings | null;
    /**
     * 
     * @type {TradingStyles}
     * @memberof AdditionalInformation
     */
    'trading_styles'?: TradingStyles | null;
    /**
     * 
     * @type {AdditionalInformationRatingHistory}
     * @memberof AdditionalInformation
     */
    'rating_history'?: AdditionalInformationRatingHistory | null;
    /**
     * 
     * @type {AdditionalInformationCommentaries}
     * @memberof AdditionalInformation
     */
    'commentaries'?: AdditionalInformationCommentaries | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformation
     */
    'additional_information_item'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationAdditionalFinancials
 */
export interface AdditionalInformationAdditionalFinancials {
    /**
     * 
     * @type {Array<AdditionalInformationFinancialItem>}
     * @memberof AdditionalInformationAdditionalFinancials
     */
    'financial_items'?: Array<AdditionalInformationFinancialItem> | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationBusinessGroup
 */
export interface AdditionalInformationBusinessGroup {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationBusinessGroup
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationBusinessGroup
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationCommentaries
 */
export interface AdditionalInformationCommentaries {
    /**
     * 
     * @type {Array<AdditionalInformationCommentary>}
     * @memberof AdditionalInformationCommentaries
     */
    'commentary'?: Array<AdditionalInformationCommentary> | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationCommentary
 */
export interface AdditionalInformationCommentary {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCommentary
     */
    'commentary_text': string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCommentary
     */
    'positive_or_negative': string;
}
/**
 * 
 * @export
 * @interface AdditionalInformationCommonRatingBanding
 */
export interface AdditionalInformationCommonRatingBanding {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCommonRatingBanding
     */
    '@value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCommonRatingBanding
     */
    '@description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationCompanyHistory
 */
export interface AdditionalInformationCompanyHistory {
    /**
     * 
     * @type {Array<AdditionalInformationEvent>}
     * @memberof AdditionalInformationCompanyHistory
     */
    'event'?: Array<AdditionalInformationEvent> | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationCompanyInGroup
 */
export interface AdditionalInformationCompanyInGroup {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'company_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'registered_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'latest_annual_accounts'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'level'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'common_rating_band'?: string | null;
    /**
     * 
     * @type {AdditionalInformationCommonRatingBanding}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'common_rating_banding'?: AdditionalInformationCommonRatingBanding | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    '@id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    '@country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    '@safe_number'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationDaySalesOutstanding
 */
export interface AdditionalInformationDaySalesOutstanding {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationDaySalesOutstanding
     */
    'day_sales_outstanding_lower'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationDaySalesOutstanding
     */
    'day_sales_outstanding_median'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationEvent
 */
export interface AdditionalInformationEvent {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationEvent
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationEvent
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationEvent
     */
    'previous_value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationEvent
     */
    'current_value'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationExtendedGroupStructure
 */
export interface AdditionalInformationExtendedGroupStructure {
    /**
     * 
     * @type {AdditionalInformationGroupStructure}
     * @memberof AdditionalInformationExtendedGroupStructure
     */
    'group_structure'?: AdditionalInformationGroupStructure | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationFinancialItem
 */
export interface AdditionalInformationFinancialItem {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationFinancialItem
     */
    'year_end_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationFinancialItem
     */
    'consolidated_subsidiaries'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationGroupStructure
 */
export interface AdditionalInformationGroupStructure {
    /**
     * 
     * @type {Array<AdditionalInformationCompanyInGroup>}
     * @memberof AdditionalInformationGroupStructure
     */
    'company_in_group'?: Array<AdditionalInformationCompanyInGroup> | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationIndustryComparison
 */
export interface AdditionalInformationIndustryComparison {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationIndustryComparison
     */
    'industry_average_credit_rating'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationIndustryComparison
     */
    'industry_average_credit_limit'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationIndustryQuartileAnalysis
 */
export interface AdditionalInformationIndustryQuartileAnalysis {
    /**
     * 
     * @type {AdditionalInformationPaymentExpectationDays}
     * @memberof AdditionalInformationIndustryQuartileAnalysis
     */
    'payment_expectation_days'?: AdditionalInformationPaymentExpectationDays | null;
    /**
     * 
     * @type {AdditionalInformationDaySalesOutstanding}
     * @memberof AdditionalInformationIndustryQuartileAnalysis
     */
    'day_sales_outstanding'?: AdditionalInformationDaySalesOutstanding | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationKvkFiling
 */
export interface AdditionalInformationKvkFiling {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationKvkFiling
     */
    'filing_date': string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationKvkFiling
     */
    'filed_event'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationKvkFiling
     */
    'filing_text'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationKvkFilings
 */
export interface AdditionalInformationKvkFilings {
    /**
     * 
     * @type {Array<AdditionalInformationKvkFiling>}
     * @memberof AdditionalInformationKvkFilings
     */
    'kvk_filing'?: Array<AdditionalInformationKvkFiling> | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationMainOfficeIndicator
 */
export interface AdditionalInformationMainOfficeIndicator {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMainOfficeIndicator
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMainOfficeIndicator
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationMisc
 */
export interface AdditionalInformationMisc {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'dateof_legal_form'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'exporter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'importer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'negative_rating'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'rsin_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'continuance_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'company_age'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'latest_accounts_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'number_of_searches_l12'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'kvk_status'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalInformationMisc
     */
    'kvk_economically_active'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'branch_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'employee_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'employee_class'?: string | null;
    /**
     * 
     * @type {AdditionalInformationBusinessGroup}
     * @memberof AdditionalInformationMisc
     */
    'business_group'?: AdditionalInformationBusinessGroup | null;
    /**
     * 
     * @type {AdditionalInformationRegistrationType}
     * @memberof AdditionalInformationMisc
     */
    'registration_type'?: AdditionalInformationRegistrationType | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'deposited_capital'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'odds_of_failure'?: string | null;
    /**
     * 
     * @type {AdditionalInformationPrimarySection}
     * @memberof AdditionalInformationMisc
     */
    'primary_section'?: AdditionalInformationPrimarySection | null;
    /**
     * 
     * @type {AdditionalInformationMainOfficeIndicator}
     * @memberof AdditionalInformationMisc
     */
    'main_office_indicator'?: AdditionalInformationMainOfficeIndicator | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'first_trading_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'first_trading_name_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'manual_activity_description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'founding_deed_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'statutaire_seal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'statutaire_seal_update_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'registered_address_update'?: string | null;
    /**
     * 
     * @type {AdditionalInformationVsi}
     * @memberof AdditionalInformationMisc
     */
    'vsi'?: AdditionalInformationVsi | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'registered_address_country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'registered_country'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationPaymentExpectationDays
 */
export interface AdditionalInformationPaymentExpectationDays {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPaymentExpectationDays
     */
    'payment_expectations_lower'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPaymentExpectationDays
     */
    'payment_expectations_median'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPaymentExpectationDays
     */
    'payment_expectations_upper'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationPaymentExpectationsSummary
 */
export interface AdditionalInformationPaymentExpectationsSummary {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPaymentExpectationsSummary
     */
    'suspension_of_payments_mora'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPaymentExpectationsSummary
     */
    'industry_average_payment_expectation_days'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPaymentExpectationsSummary
     */
    'industry_average_day_sales_outstanding'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationPrimarySection
 */
export interface AdditionalInformationPrimarySection {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPrimarySection
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPrimarySection
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationRating
 */
export interface AdditionalInformationRating {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRating
     */
    'common_description': string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRating
     */
    'common_value': string;
    /**
     * 
     * @type {number}
     * @memberof AdditionalInformationRating
     */
    'credit_limit'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRating
     */
    'provider_description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRating
     */
    'provider_value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRating
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface AdditionalInformationRatingHistory
 */
export interface AdditionalInformationRatingHistory {
    /**
     * 
     * @type {Array<AdditionalInformationRating>}
     * @memberof AdditionalInformationRatingHistory
     */
    'rating'?: Array<AdditionalInformationRating> | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationRegistrationType
 */
export interface AdditionalInformationRegistrationType {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRegistrationType
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRegistrationType
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationVsi
 */
export interface AdditionalInformationVsi {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationVsi
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationVsi
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'simple_value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'house_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postal_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'province'?: string | null;
}
/**
 * 
 * @export
 * @interface Administration
 */
export interface Administration {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof Administration
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Administration
     */
    'companyName': string;
    /**
     * Street name of administration company.
     * @type {string}
     * @memberof Administration
     */
    'streetName': string;
    /**
     * House number of administration company.
     * @type {string}
     * @memberof Administration
     */
    'houseNumber': string;
    /**
     * Zipcode of administration company.
     * @type {string}
     * @memberof Administration
     */
    'zipcode': string | null;
    /**
     * 
     * @type {string}
     * @memberof Administration
     */
    'city': string;
    /**
     * Country code of the administration (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof Administration
     */
    'countryCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof Administration
     */
    'bankAccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Administration
     */
    'bankAccountName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Administration
     */
    'logoFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Administration
     */
    'logoContentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Administration
     */
    'logoUpdatedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface AffiliatedCompanies
 */
export interface AffiliatedCompanies {
    /**
     * 
     * @type {Array<AffiliatedCompany>}
     * @memberof AffiliatedCompanies
     */
    'affiliated_company': Array<AffiliatedCompany>;
}
/**
 * 
 * @export
 * @interface AffiliatedCompany
 */
export interface AffiliatedCompany {
    /**
     * 
     * @type {string}
     * @memberof AffiliatedCompany
     */
    '@id': string;
    /**
     * 
     * @type {string}
     * @memberof AffiliatedCompany
     */
    '@country': string;
    /**
     * 
     * @type {string}
     * @memberof AffiliatedCompany
     */
    '@safe_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AffiliatedCompany
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AffiliatedCompany
     */
    'registration_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AffiliatedCompany
     */
    'status'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof AffiliatedCompany
     */
    'address'?: Address | null;
}
/**
 * 
 * @export
 * @interface Authorize
 */
export interface Authorize {
    /**
     * 
     * @type {boolean}
     * @memberof Authorize
     */
    'accept': boolean;
}
/**
 * 
 * @export
 * @interface AuthorizeRedirect
 */
export interface AuthorizeRedirect {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeRedirect
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface Bailiff
 */
export interface Bailiff {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof Bailiff
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Bailiff
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Bailiff
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Bailiff
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bailiff
     */
    'website'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bailiff
     */
    'maxExecutionCosts': string;
    /**
     * 
     * @type {string}
     * @memberof Bailiff
     */
    'info': string;
}
/**
 * 
 * @export
 * @interface BailiffConnection
 */
export interface BailiffConnection {
    /**
     * 
     * @type {PageInfoType}
     * @memberof BailiffConnection
     */
    'pageInfo': PageInfoType;
    /**
     * Total amount of records.
     * @type {number}
     * @memberof BailiffConnection
     */
    'totalCount': number;
    /**
     * Array of nodes.
     * @type {Array<Bailiff>}
     * @memberof BailiffConnection
     */
    'nodes': Array<Bailiff>;
}
/**
 * 
 * @export
 * @interface BalanceSheet
 */
export interface BalanceSheet {
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_tangible_assets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'investments'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_other_fixed_assets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_fixed_assets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_inventories'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'trade_receivables'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'miscellaneous_receivables'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_receivables'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'cash'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_current_assets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_assets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'miscellaneous_liabilities'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_current_liabilities'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'miscellaneous_liabilities_due_after1_year'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_long_term_liabilities'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_liabilities'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'called_up_share_capital'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'other_reserves'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_shareholders_equity'?: number | null;
}
/**
 * 
 * @export
 * @interface BasicInformation
 */
export interface BasicInformation {
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'business_name': string;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'registered_company_name': string;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'company_registration_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'vat_registration_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'dateof_company_registration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'dateof_starting_operations'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'legal_form'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'company_status'?: string | null;
    /**
     * 
     * @type {MainActivity}
     * @memberof BasicInformation
     */
    'principal_activity'?: MainActivity | null;
    /**
     * 
     * @type {Address}
     * @memberof BasicInformation
     */
    'contact_address'?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'contact_telephone_number'?: string | null;
}
/**
 * 
 * @export
 * @interface Cart
 */
export interface Cart {
    /**
     * 
     * @type {Array<number>}
     * @memberof Cart
     */
    'creditCases': Array<number>;
    /**
     * Temporary used, when paid will request these from CreditSafe
     * @type {Array<string>}
     * @memberof Cart
     */
    'creditCheckReferences': Array<string>;
}
/**
 * 
 * @export
 * @interface CaseAction
 */
export interface CaseAction {
    /**
     * 
     * @type {number}
     * @memberof CaseAction
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CaseAction
     */
    'type': CaseActionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CaseAction
     */
    'failed': boolean;
    /**
     * 
     * @type {string}
     * @memberof CaseAction
     */
    'failedReason'?: CaseActionFailedReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof CaseAction
     */
    'unPerformableReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAction
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAction
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAction
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAction
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAction
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAction
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAction
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAction
     */
    'performedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAction
     */
    'callbackAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CaseAction
     */
    'callSuccess'?: boolean | null;
}

export const CaseActionTypeEnum = {
    FinishedAction: 'FinishedAction',
    SmsAction: 'SmsAction',
    ContinueAction: 'ContinueAction',
    PostAction: 'PostAction',
    PhoneAction: 'PhoneAction',
    EmailAction: 'EmailAction',
    RegisteredEmailAction: 'RegisteredEmailAction'
} as const;

export type CaseActionTypeEnum = typeof CaseActionTypeEnum[keyof typeof CaseActionTypeEnum];
export const CaseActionFailedReasonEnum = {
    Unknown: 'unknown',
    Refused: 'refused',
    Left: 'left',
    NotCollected: 'not_collected',
    IncompleteAddress: 'incomplete_address',
    UnknownAddress: 'unknown_address',
    Deceased: 'deceased'
} as const;

export type CaseActionFailedReasonEnum = typeof CaseActionFailedReasonEnum[keyof typeof CaseActionFailedReasonEnum];

/**
 * 
 * @export
 * @interface CaseAttachment
 */
export interface CaseAttachment {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CaseAttachment
     */
    'id': number;
    /**
     * File name of the attachment.
     * @type {string}
     * @memberof CaseAttachment
     */
    'documentFileName': string;
    /**
     * Type of the file
     * @type {string}
     * @memberof CaseAttachment
     */
    'documentType'?: string | null;
}
/**
 * 
 * @export
 * @interface CaseTimelineConnection
 */
export interface CaseTimelineConnection {
    /**
     * 
     * @type {Array<CaseTimelineStep>}
     * @memberof CaseTimelineConnection
     */
    'nodes': Array<CaseTimelineStep>;
    /**
     * 
     * @type {CaseTimelineNodesInfo}
     * @memberof CaseTimelineConnection
     */
    'nodesInfo': CaseTimelineNodesInfo;
}
/**
 * 
 * @export
 * @interface CaseTimelineNodesInfo
 */
export interface CaseTimelineNodesInfo {
    /**
     * 
     * @type {boolean}
     * @memberof CaseTimelineNodesInfo
     */
    'preIncassoSend': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CaseTimelineNodesInfo
     */
    'notice': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CaseTimelineNodesInfo
     */
    'secondNotice': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CaseTimelineNodesInfo
     */
    'formalNotice': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CaseTimelineNodesInfo
     */
    'preJudiciary': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CaseTimelineNodesInfo
     */
    'judiciary': boolean;
    /**
     * 
     * @type {Array<CaseTimelineSendLetter>}
     * @memberof CaseTimelineNodesInfo
     */
    'sendLetters': Array<CaseTimelineSendLetter>;
}
/**
 * 
 * @export
 * @interface CaseTimelineSendLetter
 */
export interface CaseTimelineSendLetter {
    /**
     * 
     * @type {number}
     * @memberof CaseTimelineSendLetter
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineSendLetter
     */
    'reference': string;
}
/**
 * 
 * @export
 * @interface CaseTimelineStep
 */
export interface CaseTimelineStep {
    /**
     * 
     * @type {number}
     * @memberof CaseTimelineStep
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStep
     */
    'type': CaseTimelineStepTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CaseTimelineStep
     */
    'performed'?: boolean | null;
    /**
     * Amount of the payment, if \"type\" is \"payment\".
     * @type {number}
     * @memberof CaseTimelineStep
     */
    'amount'?: number | null;
    /**
     * Title of the step
     * @type {string}
     * @memberof CaseTimelineStep
     */
    'title'?: string | null;
    /**
     * Message of the note, if \"type\" is \"note\" or \"message\".
     * @type {string}
     * @memberof CaseTimelineStep
     */
    'message'?: string | null;
    /**
     * User of the note, if \"type\" is \"message\".
     * @type {string}
     * @memberof CaseTimelineStep
     */
    'fromUser'?: string | null;
    /**
     * Date the step is scheduled to perform.
     * @type {string}
     * @memberof CaseTimelineStep
     */
    'date': string;
    /**
     * If we should show step specific actions the user can do.
     * @type {boolean}
     * @memberof CaseTimelineStep
     */
    'showActions'?: boolean;
    /**
     * 
     * @type {Array<CaseAction>}
     * @memberof CaseTimelineStep
     */
    'actions'?: Array<CaseAction> | null;
}

export const CaseTimelineStepTypeEnum = {
    Note: 'note',
    Message: 'message',
    Payment: 'payment',
    PaymentRemission: 'payment-remission',
    Step: 'step'
} as const;

export type CaseTimelineStepTypeEnum = typeof CaseTimelineStepTypeEnum[keyof typeof CaseTimelineStepTypeEnum];

/**
 * 
 * @export
 * @interface CheckoutComplete
 */
export interface CheckoutComplete {
    /**
     * IDs of the credit cases that are now started.
     * @type {Array<string>}
     * @memberof CheckoutComplete
     */
    'creditCaseIds': Array<string>;
    /**
     * IDs of the credit checks that are now available.
     * @type {Array<string>}
     * @memberof CheckoutComplete
     */
    'creditCheckIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ClientApplication
 */
export interface ClientApplication {
    /**
     * 
     * @type {string}
     * @memberof ClientApplication
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ClientApplication
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'aId': string;
    /**
     * Name of the company.
     * @type {string}
     * @memberof Company
     */
    'companyName': string | null;
    /**
     * Street name of the company.
     * @type {string}
     * @memberof Company
     */
    'streetName': string | null;
    /**
     * House number of the company.
     * @type {string}
     * @memberof Company
     */
    'houseNumber': string | null;
    /**
     * Zipcode of the company.
     * @type {string}
     * @memberof Company
     */
    'zipcode': string | null;
    /**
     * City of the company.
     * @type {string}
     * @memberof Company
     */
    'city': string | null;
    /**
     * Country code of the company (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof Company
     */
    'countryCode': string | null;
    /**
     * CoC number of the company.
     * @type {string}
     * @memberof Company
     */
    'kvkNumber'?: string | null;
    /**
     * Phone number of the company.
     * @type {string}
     * @memberof Company
     */
    'phoneNumber': string;
    /**
     * IBAN of the company.
     * @type {string}
     * @memberof Company
     */
    'bankAccountNumber': string | null;
    /**
     * Name of the bank account.
     * @type {string}
     * @memberof Company
     */
    'bankAccountName': string | null;
    /**
     * Contact name to use on letters.
     * @type {string}
     * @memberof Company
     */
    'letterContactName'?: string | null;
    /**
     * Email address to use on letters.
     * @type {string}
     * @memberof Company
     */
    'letterEmail'?: string | null;
    /**
     * Phone number to use on letters.
     * @type {string}
     * @memberof Company
     */
    'letterPhone'?: string | null;
    /**
     * CoC number to use on letters.
     * @type {string}
     * @memberof Company
     */
    'letterKvkNumber'?: string | null;
    /**
     * Vat number to use on letters.
     * @type {string}
     * @memberof Company
     */
    'letterVatNumber'?: string | null;
    /**
     * Company completed the signup.
     * @type {boolean}
     * @memberof Company
     */
    'signupCompleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'logoFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'logoContentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'logoUpdatedAt'?: string | null;
    /**
     * Auto-forward new credit cases to the selected bailiff.
     * @type {boolean}
     * @memberof Company
     */
    'autoForward'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'interestType': CompanyInterestTypeEnum;
    /**
     * 
     * @type {InterestStrategy}
     * @memberof Company
     */
    'interestStrategy'?: InterestStrategy | null;
    /**
     * 
     * @type {CompanyBailiff}
     * @memberof Company
     */
    'bailiff'?: CompanyBailiff | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'bailiffId'?: number | null;
}

export const CompanyInterestTypeEnum = {
    None: 'none',
    Custom: 'custom',
    Legal: 'legal'
} as const;

export type CompanyInterestTypeEnum = typeof CompanyInterestTypeEnum[keyof typeof CompanyInterestTypeEnum];

/**
 * The bailiff assigned to the case.
 * @export
 * @interface CompanyBailiff
 */
export interface CompanyBailiff {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CompanyBailiff
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyBailiff
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBailiff
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBailiff
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyBailiff
     */
    'website'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyBailiff
     */
    'maxExecutionCosts': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBailiff
     */
    'info': string;
}
/**
 * 
 * @export
 * @interface CompanyIdentification
 */
export interface CompanyIdentification {
    /**
     * 
     * @type {BasicInformation}
     * @memberof CompanyIdentification
     */
    'basic_information': BasicInformation;
    /**
     * 
     * @type {Activities}
     * @memberof CompanyIdentification
     */
    'activities'?: Activities | null;
}
/**
 * 
 * @export
 * @interface CompanySummary
 */
export interface CompanySummary {
    /**
     * 
     * @type {string}
     * @memberof CompanySummary
     */
    'business_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySummary
     */
    'company_registration_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySummary
     */
    'company_status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySummary
     */
    'country'?: string | null;
    /**
     * 
     * @type {CreditRating}
     * @memberof CompanySummary
     */
    'credit_rating': CreditRating;
    /**
     * 
     * @type {string}
     * @memberof CompanySummary
     */
    'latest_shareholders_equity_figure'?: string | null;
    /**
     * 
     * @type {MainActivity}
     * @memberof CompanySummary
     */
    'main_activity'?: MainActivity | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySummary
     */
    'number'?: string | null;
}
/**
 * 
 * @export
 * @interface ContactInformation
 */
export interface ContactInformation {
    /**
     * 
     * @type {MainAddress}
     * @memberof ContactInformation
     */
    'main_address'?: MainAddress | null;
    /**
     * 
     * @type {OtherAddresses}
     * @memberof ContactInformation
     */
    'other_addresses'?: OtherAddresses | null;
    /**
     * 
     * @type {EmailAddresses}
     * @memberof ContactInformation
     */
    'email_addresses'?: EmailAddresses | null;
    /**
     * 
     * @type {Websites}
     * @memberof ContactInformation
     */
    'websites'?: Websites | null;
}
/**
 * 
 * @export
 * @interface CreateAdministration
 */
export interface CreateAdministration {
    /**
     * 
     * @type {string}
     * @memberof CreateAdministration
     */
    'companyName': string;
    /**
     * Street name of administration company.
     * @type {string}
     * @memberof CreateAdministration
     */
    'streetName': string;
    /**
     * House number of administration company.
     * @type {string}
     * @memberof CreateAdministration
     */
    'houseNumber': string;
    /**
     * Zipcode of administration company.
     * @type {string}
     * @memberof CreateAdministration
     */
    'zipcode': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdministration
     */
    'city': string;
    /**
     * Country code of the administration (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof CreateAdministration
     */
    'countryCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdministration
     */
    'bankAccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdministration
     */
    'bankAccountName'?: string | null;
    /**
     * Contact name to use on letters.
     * @type {string}
     * @memberof CreateAdministration
     */
    'letterContactName'?: string | null;
    /**
     * Email address to use on letters.
     * @type {string}
     * @memberof CreateAdministration
     */
    'letterEmail'?: string | null;
    /**
     * Phone number to use on letters.
     * @type {string}
     * @memberof CreateAdministration
     */
    'letterPhone'?: string | null;
    /**
     * CoC number to use on letters.
     * @type {string}
     * @memberof CreateAdministration
     */
    'letterKvkNumber'?: string | null;
    /**
     * Vat number to use on letters.
     * @type {string}
     * @memberof CreateAdministration
     */
    'letterVatNumber'?: string | null;
    /**
     * Email address for notifications.
     * @type {string}
     * @memberof CreateAdministration
     */
    'contactEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdministration
     */
    'interestType': CreateAdministrationInterestTypeEnum;
    /**
     * 
     * @type {InterestStrategy}
     * @memberof CreateAdministration
     */
    'interestStrategy'?: InterestStrategy | null;
}

export const CreateAdministrationInterestTypeEnum = {
    None: 'none',
    Custom: 'custom',
    Legal: 'legal'
} as const;

export type CreateAdministrationInterestTypeEnum = typeof CreateAdministrationInterestTypeEnum[keyof typeof CreateAdministrationInterestTypeEnum];

/**
 * 
 * @export
 * @interface CreateCreditCase
 */
export interface CreateCreditCase {
    /**
     * 
     * @type {number}
     * @memberof CreateCreditCase
     */
    'administrationId'?: number | null;
    /**
     * Required when `isCompany` = `true`.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateCreditCase
     */
    'gender'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCreditCase
     */
    'firstname'?: string | null;
    /**
     * Required when `isCompany` = `false`.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'lastname'?: string | null;
    /**
     * Email of the debtor.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCreditCase
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCreditCase
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCreditCase
     */
    'isCompany': boolean;
    /**
     * Street of the debtor.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'streetName': string;
    /**
     * House number of the debtor.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'houseNumber': string;
    /**
     * Zipcode of the debtor.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'zipcode': string;
    /**
     * City of the debtor.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'city': string;
    /**
     * Country code of the debtor (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof CreateCreditCase
     */
    'countryCode': string;
    /**
     * Language code to communicate in with the debtor.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'languageCode': CreateCreditCaseLanguageCodeEnum;
    /**
     * Should the credit case start with a pre-incasso.
     * @type {boolean}
     * @memberof CreateCreditCase
     */
    'preIncasso'?: boolean;
    /**
     * Should the case be auto-forwarded to the selected bailiff.
     * @type {boolean}
     * @memberof CreateCreditCase
     */
    'autoForward'?: boolean;
    /**
     * Invoices for this case.
     * @type {Array<CreateCreditCaseInvoice>}
     * @memberof CreateCreditCase
     */
    'invoices': Array<CreateCreditCaseInvoice>;
}

export const CreateCreditCaseLanguageCodeEnum = {
    Nl: 'nl',
    En: 'en'
} as const;

export type CreateCreditCaseLanguageCodeEnum = typeof CreateCreditCaseLanguageCodeEnum[keyof typeof CreateCreditCaseLanguageCodeEnum];

/**
 * 
 * @export
 * @interface CreateCreditCaseInvoice
 */
export interface CreateCreditCaseInvoice {
    /**
     * Date of the invoice.
     * @type {string}
     * @memberof CreateCreditCaseInvoice
     */
    'invoiceDate': string;
    /**
     * Due date of the invoice.
     * @type {string}
     * @memberof CreateCreditCaseInvoice
     */
    'dueDate': string;
    /**
     * ID/Reference of the invoice.
     * @type {string}
     * @memberof CreateCreditCaseInvoice
     */
    'invoiceId': string;
    /**
     * Amount of the invoice including taxes.
     * @type {number}
     * @memberof CreateCreditCaseInvoice
     */
    'priceInclTax': number;
}
/**
 * 
 * @export
 * @interface CreateDebtorMessage
 */
export interface CreateDebtorMessage {
    /**
     * 
     * @type {string}
     * @memberof CreateDebtorMessage
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDebtorMessage
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateInvoice
 */
export interface CreateInvoice {
    /**
     * Date of the invoice.
     * @type {string}
     * @memberof CreateInvoice
     */
    'invoiceDate': string;
    /**
     * Due date of the invoice.
     * @type {string}
     * @memberof CreateInvoice
     */
    'dueDate': string;
    /**
     * ID/Reference of the invoice.
     * @type {string}
     * @memberof CreateInvoice
     */
    'invoiceId': string;
    /**
     * Amount of the invoice including taxes.
     * @type {number}
     * @memberof CreateInvoice
     */
    'priceInclTax': number;
}
/**
 * 
 * @export
 * @interface CreateMessage
 */
export interface CreateMessage {
    /**
     * 
     * @type {string}
     * @memberof CreateMessage
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CreateNote
 */
export interface CreateNote {
    /**
     * Message of the note.
     * @type {string}
     * @memberof CreateNote
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNote
     */
    'notifyEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNote
     */
    'notifyDate'?: string | null;
}
/**
 * 
 * @export
 * @interface CreatePayment
 */
export interface CreatePayment {
    /**
     * 
     * @type {number}
     * @memberof CreatePayment
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof CreatePayment
     */
    'paymentDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePayment
     */
    'createRemissionPayment'?: boolean;
}
/**
 * 
 * @export
 * @interface CreatePaymentPlan
 */
export interface CreatePaymentPlan {
    /**
     * 
     * @type {Array<NewPlannedPayment>}
     * @memberof CreatePaymentPlan
     */
    'plannedPayments': Array<NewPlannedPayment>;
}
/**
 * 
 * @export
 * @interface CreateUser
 */
export interface CreateUser {
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'passwordCheck': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUser
     */
    'receiveNotifications': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUser
     */
    'receiveNewsletter': boolean;
}
/**
 * 
 * @export
 * @interface CreditCase
 */
export interface CreditCase {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCase
     */
    'id': number;
    /**
     * Date time of when the recourse is created.
     * @type {string}
     * @memberof CreditCase
     */
    'createdAt': string;
    /**
     * Date time of when the recourse last updated.
     * @type {string}
     * @memberof CreditCase
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'administrationId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'state': CreditCaseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'subState': CreditCaseSubStateEnum;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'invoicesPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'casePrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'interestPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'totalPriceOpen': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'baseCasePrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'postPaymentsPrice': number;
    /**
     * The amount the debtor paid before collection started.
     * @type {number}
     * @memberof CreditCase
     */
    'prePaymentsPrice': number;
    /**
     * Name of the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'debtorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'identificationNumber': string;
    /**
     * Required when `isCompany` = `true`.
     * @type {string}
     * @memberof CreditCase
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'gender'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'firstname'?: string | null;
    /**
     * Required when `isCompany` = `false`.
     * @type {string}
     * @memberof CreditCase
     */
    'lastname'?: string | null;
    /**
     * Email of the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreditCase
     */
    'isCompany': boolean;
    /**
     * Street of the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'streetName': string;
    /**
     * House number of the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'houseNumber': string;
    /**
     * Zipcode of the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'zipcode': string;
    /**
     * City of the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'city': string;
    /**
     * Country code of the debtor (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof CreditCase
     */
    'countryCode': string;
    /**
     * Language code to communicate in with the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'languageCode': CreditCaseLanguageCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'startedAt'?: string | null;
    /**
     * Should the credit case start with a pre-incasso.
     * @type {boolean}
     * @memberof CreditCase
     */
    'preIncasso'?: boolean;
    /**
     * 
     * @type {CreditCaseCreditor}
     * @memberof CreditCase
     */
    'creditor': CreditCaseCreditor;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'interestType': CreditCaseInterestTypeEnum;
    /**
     * Should the case be auto-forwarded to the selected bailiff.
     * @type {boolean}
     * @memberof CreditCase
     */
    'autoForward'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'origin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'currency': string;
}

export const CreditCaseStateEnum = {
    Draft: 'draft',
    Paused: 'paused',
    Forwarded: 'forwarded',
    Closed: 'closed',
    Finished: 'finished',
    Paid: 'paid',
    Open: 'open'
} as const;

export type CreditCaseStateEnum = typeof CreditCaseStateEnum[keyof typeof CreditCaseStateEnum];
export const CreditCaseSubStateEnum = {
    Draft: 'draft',
    Notice: 'notice',
    Paid: 'paid',
    SecondNotice: 'second_notice',
    FormalNotice: 'formal_notice',
    PreJudiciary: 'pre_judiciary',
    PreDebtCollection: 'pre_debt_collection',
    Judiciary: 'judiciary',
    Finished: 'finished',
    PaymentPlan: 'payment_plan'
} as const;

export type CreditCaseSubStateEnum = typeof CreditCaseSubStateEnum[keyof typeof CreditCaseSubStateEnum];
export const CreditCaseLanguageCodeEnum = {
    Nl: 'nl',
    En: 'en'
} as const;

export type CreditCaseLanguageCodeEnum = typeof CreditCaseLanguageCodeEnum[keyof typeof CreditCaseLanguageCodeEnum];
export const CreditCaseInterestTypeEnum = {
    None: 'none',
    Custom: 'custom',
    Legal: 'legal'
} as const;

export type CreditCaseInterestTypeEnum = typeof CreditCaseInterestTypeEnum[keyof typeof CreditCaseInterestTypeEnum];

/**
 * 
 * @export
 * @interface CreditCaseConnection
 */
export interface CreditCaseConnection {
    /**
     * 
     * @type {PageInfoType}
     * @memberof CreditCaseConnection
     */
    'pageInfo': PageInfoType;
    /**
     * Total amount of records.
     * @type {number}
     * @memberof CreditCaseConnection
     */
    'totalCount': number;
    /**
     * Array of nodes.
     * @type {Array<CreditCase>}
     * @memberof CreditCaseConnection
     */
    'nodes': Array<CreditCase>;
}
/**
 * 
 * @export
 * @interface CreditCaseCounts
 */
export interface CreditCaseCounts {
    /**
     * 
     * @type {number}
     * @memberof CreditCaseCounts
     */
    'openCount': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCaseCounts
     */
    'draftCount': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCaseCounts
     */
    'preDebtCollectionCount': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCaseCounts
     */
    'openWithUnreadMessagesCount': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCaseCounts
     */
    'overduePaymentCount': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCaseCounts
     */
    'pausedCount': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCaseCounts
     */
    'finishedCount': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCaseCounts
     */
    'judiciaryCount': number;
}
/**
 * 
 * @export
 * @interface CreditCaseCreditor
 */
export interface CreditCaseCreditor {
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'streetName': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'zipcode': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'bankAccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'bankAccountName'?: string | null;
}
/**
 * 
 * @export
 * @interface CreditCaseReference
 */
export interface CreditCaseReference {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCaseReference
     */
    'id': number;
    /**
     * Date time of when the recourse is created.
     * @type {string}
     * @memberof CreditCaseReference
     */
    'createdAt': string;
    /**
     * Date time of when the recourse last updated.
     * @type {string}
     * @memberof CreditCaseReference
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseReference
     */
    'identificationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseReference
     */
    'state'?: CreditCaseReferenceStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseReference
     */
    'subState'?: CreditCaseReferenceSubStateEnum;
}

export const CreditCaseReferenceStateEnum = {
    Draft: 'draft',
    Paused: 'paused',
    Forwarded: 'forwarded',
    Closed: 'closed',
    Finished: 'finished',
    Paid: 'paid',
    Open: 'open'
} as const;

export type CreditCaseReferenceStateEnum = typeof CreditCaseReferenceStateEnum[keyof typeof CreditCaseReferenceStateEnum];
export const CreditCaseReferenceSubStateEnum = {
    Draft: 'draft',
    Notice: 'notice',
    Paid: 'paid',
    SecondNotice: 'second_notice',
    FormalNotice: 'formal_notice',
    PreJudiciary: 'pre_judiciary',
    PreDebtCollection: 'pre_debt_collection',
    Judiciary: 'judiciary',
    Finished: 'finished',
    PaymentPlan: 'payment_plan'
} as const;

export type CreditCaseReferenceSubStateEnum = typeof CreditCaseReferenceSubStateEnum[keyof typeof CreditCaseReferenceSubStateEnum];

/**
 * 
 * @export
 * @interface CreditCheck
 */
export interface CreditCheck {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCheck
     */
    'id': number;
    /**
     * Date time of when the recourse is created.
     * @type {string}
     * @memberof CreditCheck
     */
    'createdAt': string;
    /**
     * Date time of when the recourse last updated.
     * @type {string}
     * @memberof CreditCheck
     */
    'updatedAt': string;
    /**
     * Reference of the report.
     * @type {string}
     * @memberof CreditCheck
     */
    'reference'?: string | null;
    /**
     * 
     * @type {CreditCheckReport}
     * @memberof CreditCheck
     */
    'report'?: CreditCheckReport | null;
    /**
     * Name of the business that this report is for.
     * @type {string}
     * @memberof CreditCheck
     */
    'businessName'?: string | null;
}
/**
 * 
 * @export
 * @interface CreditCheckConnection
 */
export interface CreditCheckConnection {
    /**
     * 
     * @type {PageInfoType}
     * @memberof CreditCheckConnection
     */
    'pageInfo': PageInfoType;
    /**
     * Total amount of records.
     * @type {number}
     * @memberof CreditCheckConnection
     */
    'totalCount': number;
    /**
     * Array of nodes.
     * @type {Array<CreditCheck>}
     * @memberof CreditCheckConnection
     */
    'nodes': Array<CreditCheck>;
}
/**
 * The report.
 * @export
 * @interface CreditCheckReport
 */
export interface CreditCheckReport {
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReport
     */
    '@company_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReport
     */
    '@language': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReport
     */
    '@report_currency': string;
    /**
     * 
     * @type {CompanySummary}
     * @memberof CreditCheckReport
     */
    'company_summary': CompanySummary;
    /**
     * 
     * @type {CompanyIdentification}
     * @memberof CreditCheckReport
     */
    'company_identification': CompanyIdentification;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReport
     */
    'data_of_report'?: string | null;
    /**
     * 
     * @type {ShareCapitalStructure}
     * @memberof CreditCheckReport
     */
    'share_capital_structure'?: ShareCapitalStructure | null;
    /**
     * 
     * @type {CreditScore}
     * @memberof CreditCheckReport
     */
    'credit_score'?: CreditScore | null;
    /**
     * 
     * @type {ContactInformation}
     * @memberof CreditCheckReport
     */
    'contact_information'?: ContactInformation | null;
    /**
     * 
     * @type {Directors}
     * @memberof CreditCheckReport
     */
    'directors'?: Directors | null;
    /**
     * 
     * @type {OtherInformation}
     * @memberof CreditCheckReport
     */
    'other_information'?: OtherInformation | null;
    /**
     * 
     * @type {GroupStructure}
     * @memberof CreditCheckReport
     */
    'group_structure'?: GroupStructure | null;
    /**
     * 
     * @type {FinancialStatements}
     * @memberof CreditCheckReport
     */
    'financial_statements'?: FinancialStatements | null;
    /**
     * 
     * @type {AdditionalInformation}
     * @memberof CreditCheckReport
     */
    'additional_information'?: AdditionalInformation | null;
}
/**
 * 
 * @export
 * @interface CreditCheckReportDirector
 */
export interface CreditCheckReportDirector {
    /**
     * 
     * @type {Address}
     * @memberof CreditCheckReportDirector
     */
    'address'?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportDirector
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportDirector
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportDirector
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportDirector
     */
    'position'?: string | null;
}
/**
 * 
 * @export
 * @interface CreditCheckReportPreviousDirector
 */
export interface CreditCheckReportPreviousDirector {
    /**
     * 
     * @type {Address}
     * @memberof CreditCheckReportPreviousDirector
     */
    'address'?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportPreviousDirector
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportPreviousDirector
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportPreviousDirector
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportPreviousDirector
     */
    'position'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportPreviousDirector
     */
    'resignation_date'?: string | null;
}
/**
 * 
 * @export
 * @interface CreditRating
 */
export interface CreditRating {
    /**
     * 
     * @type {string}
     * @memberof CreditRating
     */
    'common_description': string;
    /**
     * 
     * @type {string}
     * @memberof CreditRating
     */
    'common_value': string;
    /**
     * 
     * @type {number}
     * @memberof CreditRating
     */
    'credit_limit'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreditRating
     */
    'provider_description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditRating
     */
    'provider_value'?: string | null;
}
/**
 * 
 * @export
 * @interface CreditScore
 */
export interface CreditScore {
    /**
     * 
     * @type {CreditRating}
     * @memberof CreditScore
     */
    'current_credit_rating'?: CreditRating | null;
    /**
     * 
     * @type {CreditRating}
     * @memberof CreditScore
     */
    'previous_credit_rating'?: CreditRating | null;
    /**
     * 
     * @type {string}
     * @memberof CreditScore
     */
    'date_of_latest_rating_change'?: string | null;
}
/**
 * 
 * @export
 * @interface CurrentDirectors
 */
export interface CurrentDirectors {
    /**
     * 
     * @type {Array<CreditCheckReportDirector>}
     * @memberof CurrentDirectors
     */
    'director'?: Array<CreditCheckReportDirector> | null;
}
/**
 * 
 * @export
 * @interface DashboardStats
 */
export interface DashboardStats {
    /**
     * Credit cases with an open status.
     * @type {Array<CreditCaseReference>}
     * @memberof DashboardStats
     */
    'creditCases': Array<CreditCaseReference>;
    /**
     * 
     * @type {CreditCaseCounts}
     * @memberof DashboardStats
     */
    'creditCaseCounts': CreditCaseCounts;
}
/**
 * 
 * @export
 * @interface Debtor
 */
export interface Debtor {
    /**
     * Required when `isCompany` = `true`.
     * @type {string}
     * @memberof Debtor
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Debtor
     */
    'firstname'?: string | null;
    /**
     * Required when `isCompany` = `false`.
     * @type {string}
     * @memberof Debtor
     */
    'lastname'?: string | null;
    /**
     * Email of the debtor.
     * @type {string}
     * @memberof Debtor
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Debtor
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Debtor
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Debtor
     */
    'isCompany': boolean;
    /**
     * Street of the debtor.
     * @type {string}
     * @memberof Debtor
     */
    'streetName': string;
    /**
     * House number of the debtor.
     * @type {string}
     * @memberof Debtor
     */
    'houseNumber': string;
    /**
     * Zipcode of the debtor.
     * @type {string}
     * @memberof Debtor
     */
    'zipcode': string;
    /**
     * City of the debtor.
     * @type {string}
     * @memberof Debtor
     */
    'city': string;
    /**
     * Country code of the debtor (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof Debtor
     */
    'countryCode': string;
    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof Debtor
     */
    'creditCaseId': number;
}
/**
 * 
 * @export
 * @interface DebtorCaseAction
 */
export interface DebtorCaseAction {
    /**
     * 
     * @type {number}
     * @memberof DebtorCaseAction
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DebtorCaseAction
     */
    'type': DebtorCaseActionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DebtorCaseAction
     */
    'failed': boolean;
    /**
     * 
     * @type {string}
     * @memberof DebtorCaseAction
     */
    'failedReason'?: DebtorCaseActionFailedReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof DebtorCaseAction
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtorCaseAction
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtorCaseAction
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtorCaseAction
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtorCaseAction
     */
    'performedAt'?: string | null;
}

export const DebtorCaseActionTypeEnum = {
    FinishedAction: 'FinishedAction',
    SmsAction: 'SmsAction',
    ContinueAction: 'ContinueAction',
    PostAction: 'PostAction',
    PhoneAction: 'PhoneAction',
    EmailAction: 'EmailAction',
    RegisteredEmailAction: 'RegisteredEmailAction'
} as const;

export type DebtorCaseActionTypeEnum = typeof DebtorCaseActionTypeEnum[keyof typeof DebtorCaseActionTypeEnum];
export const DebtorCaseActionFailedReasonEnum = {
    Unknown: 'unknown',
    Refused: 'refused',
    Left: 'left',
    NotCollected: 'not_collected',
    IncompleteAddress: 'incomplete_address',
    UnknownAddress: 'unknown_address',
    Deceased: 'deceased'
} as const;

export type DebtorCaseActionFailedReasonEnum = typeof DebtorCaseActionFailedReasonEnum[keyof typeof DebtorCaseActionFailedReasonEnum];

/**
 * 
 * @export
 * @interface DebtorCaseTimelineStep
 */
export interface DebtorCaseTimelineStep {
    /**
     * 
     * @type {number}
     * @memberof DebtorCaseTimelineStep
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DebtorCaseTimelineStep
     */
    'type': DebtorCaseTimelineStepTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DebtorCaseTimelineStep
     */
    'performed'?: boolean | null;
    /**
     * Amount of the payment, if \"type\" is \"payment\".
     * @type {number}
     * @memberof DebtorCaseTimelineStep
     */
    'amount'?: number | null;
    /**
     * Title of the step
     * @type {string}
     * @memberof DebtorCaseTimelineStep
     */
    'title'?: string | null;
    /**
     * Message of the note, if \"type\" is \"note\" or \"message\".
     * @type {string}
     * @memberof DebtorCaseTimelineStep
     */
    'message'?: string | null;
    /**
     * User of the note, if \"type\" is \"message\".
     * @type {string}
     * @memberof DebtorCaseTimelineStep
     */
    'fromUser'?: string | null;
    /**
     * Date the step is scheduled to perform.
     * @type {string}
     * @memberof DebtorCaseTimelineStep
     */
    'date': string;
    /**
     * If we should show step specific actions the user can do.
     * @type {boolean}
     * @memberof DebtorCaseTimelineStep
     */
    'showActions'?: boolean;
    /**
     * 
     * @type {Array<DebtorCaseAction>}
     * @memberof DebtorCaseTimelineStep
     */
    'actions'?: Array<DebtorCaseAction> | null;
}

export const DebtorCaseTimelineStepTypeEnum = {
    Note: 'note',
    Message: 'message',
    Payment: 'payment',
    PaymentRemission: 'payment-remission',
    Step: 'step'
} as const;

export type DebtorCaseTimelineStepTypeEnum = typeof DebtorCaseTimelineStepTypeEnum[keyof typeof DebtorCaseTimelineStepTypeEnum];

/**
 * 
 * @export
 * @interface DebtorCreditCase
 */
export interface DebtorCreditCase {
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'state': DebtorCreditCaseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'subState': DebtorCreditCaseSubStateEnum;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'invoicesPrice': number;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'casePrice': number;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'interestPrice': number;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'totalPriceOpen': number;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'baseCasePrice': number;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'postPaymentsPrice': number;
    /**
     * The amount the debtor paid before collection started.
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'prePaymentsPrice': number;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'identificationNumber': string;
    /**
     * Required when `isCompany` = `true`.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'firstname'?: string | null;
    /**
     * Required when `isCompany` = `false`.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'lastname'?: string | null;
    /**
     * Email of the debtor.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'phoneNumber'?: string | null;
    /**
     * Street of the debtor.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'streetName': string;
    /**
     * House number of the debtor.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'houseNumber': string;
    /**
     * Zipcode of the debtor.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'zipcode': string;
    /**
     * City of the debtor.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'city': string;
    /**
     * Country code of the debtor (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'countryCode': string;
    /**
     * 
     * @type {CreditCaseCreditor}
     * @memberof DebtorCreditCase
     */
    'creditor': CreditCaseCreditor;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'interestType': DebtorCreditCaseInterestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'currency': string;
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof DebtorCreditCase
     */
    'invoices': Array<Invoice>;
    /**
     * 
     * @type {Array<CaseAttachment>}
     * @memberof DebtorCreditCase
     */
    'attachments': Array<CaseAttachment>;
    /**
     * Timeline of the credit case.
     * @type {Array<DebtorCaseTimelineStep>}
     * @memberof DebtorCreditCase
     */
    'timeline': Array<DebtorCaseTimelineStep>;
}

export const DebtorCreditCaseStateEnum = {
    Draft: 'draft',
    Paused: 'paused',
    Forwarded: 'forwarded',
    Closed: 'closed',
    Finished: 'finished',
    Paid: 'paid',
    Open: 'open'
} as const;

export type DebtorCreditCaseStateEnum = typeof DebtorCreditCaseStateEnum[keyof typeof DebtorCreditCaseStateEnum];
export const DebtorCreditCaseSubStateEnum = {
    Draft: 'draft',
    Notice: 'notice',
    Paid: 'paid',
    SecondNotice: 'second_notice',
    FormalNotice: 'formal_notice',
    PreJudiciary: 'pre_judiciary',
    PreDebtCollection: 'pre_debt_collection',
    Judiciary: 'judiciary',
    Finished: 'finished',
    PaymentPlan: 'payment_plan'
} as const;

export type DebtorCreditCaseSubStateEnum = typeof DebtorCreditCaseSubStateEnum[keyof typeof DebtorCreditCaseSubStateEnum];
export const DebtorCreditCaseInterestTypeEnum = {
    None: 'none',
    Custom: 'custom',
    Legal: 'legal'
} as const;

export type DebtorCreditCaseInterestTypeEnum = typeof DebtorCreditCaseInterestTypeEnum[keyof typeof DebtorCreditCaseInterestTypeEnum];

/**
 * 
 * @export
 * @interface Directors
 */
export interface Directors {
    /**
     * 
     * @type {CurrentDirectors}
     * @memberof Directors
     */
    'current_directors'?: CurrentDirectors | null;
    /**
     * 
     * @type {PreviousDirectors}
     * @memberof Directors
     */
    'previous_directors'?: PreviousDirectors | null;
}
/**
 * 
 * @export
 * @interface EmailAddresses
 */
export interface EmailAddresses {
    /**
     * 
     * @type {string}
     * @memberof EmailAddresses
     */
    'email_address'?: string | null;
}
/**
 * 
 * @export
 * @interface EmployeeInformation
 */
export interface EmployeeInformation {
    /**
     * 
     * @type {string}
     * @memberof EmployeeInformation
     */
    'year'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeInformation
     */
    'number_of_employees'?: string | null;
}
/**
 * 
 * @export
 * @interface EmployeesInformation
 */
export interface EmployeesInformation {
    /**
     * 
     * @type {Array<EmployeeInformation>}
     * @memberof EmployeesInformation
     */
    'employee_information'?: Array<EmployeeInformation> | null;
}
/**
 * 
 * @export
 * @interface FinancialStatement
 */
export interface FinancialStatement {
    /**
     * 
     * @type {string}
     * @memberof FinancialStatement
     */
    'year_end_date': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialStatement
     */
    'number_of_weeks': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialStatement
     */
    'currency': string;
    /**
     * 
     * @type {boolean}
     * @memberof FinancialStatement
     */
    'consolidated_accounts'?: boolean | null;
    /**
     * 
     * @type {BalanceSheet}
     * @memberof FinancialStatement
     */
    'balance_sheet': BalanceSheet;
    /**
     * 
     * @type {OtherFinancials}
     * @memberof FinancialStatement
     */
    'other_financials': OtherFinancials;
    /**
     * 
     * @type {Ratios}
     * @memberof FinancialStatement
     */
    'ratios': Ratios;
    /**
     * 
     * @type {ProfitAndLoss}
     * @memberof FinancialStatement
     */
    'profit_and_loss': ProfitAndLoss;
}
/**
 * 
 * @export
 * @interface FinancialStatements
 */
export interface FinancialStatements {
    /**
     * 
     * @type {Array<FinancialStatement>}
     * @memberof FinancialStatements
     */
    'financial_statement': Array<FinancialStatement>;
}
/**
 * 
 * @export
 * @interface FindAdministration
 */
export interface FindAdministration {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof FindAdministration
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FindAdministration
     */
    'companyName': string;
    /**
     * Street name of administration company.
     * @type {string}
     * @memberof FindAdministration
     */
    'streetName': string;
    /**
     * House number of administration company.
     * @type {string}
     * @memberof FindAdministration
     */
    'houseNumber': string;
    /**
     * Zipcode of administration company.
     * @type {string}
     * @memberof FindAdministration
     */
    'zipcode': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAdministration
     */
    'city': string;
    /**
     * Country code of the administration (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof FindAdministration
     */
    'countryCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAdministration
     */
    'bankAccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAdministration
     */
    'bankAccountName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAdministration
     */
    'logoFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAdministration
     */
    'logoContentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAdministration
     */
    'logoUpdatedAt'?: string | null;
    /**
     * Contact name to use on letters.
     * @type {string}
     * @memberof FindAdministration
     */
    'letterContactName'?: string | null;
    /**
     * Email address to use on letters.
     * @type {string}
     * @memberof FindAdministration
     */
    'letterEmail'?: string | null;
    /**
     * Phone number to use on letters.
     * @type {string}
     * @memberof FindAdministration
     */
    'letterPhone'?: string | null;
    /**
     * CoC number to use on letters.
     * @type {string}
     * @memberof FindAdministration
     */
    'letterKvkNumber'?: string | null;
    /**
     * Vat number to use on letters.
     * @type {string}
     * @memberof FindAdministration
     */
    'letterVatNumber'?: string | null;
    /**
     * Email address for notifications.
     * @type {string}
     * @memberof FindAdministration
     */
    'contactEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAdministration
     */
    'interestType': FindAdministrationInterestTypeEnum;
    /**
     * 
     * @type {InterestStrategy}
     * @memberof FindAdministration
     */
    'interestStrategy'?: InterestStrategy | null;
}

export const FindAdministrationInterestTypeEnum = {
    None: 'none',
    Custom: 'custom',
    Legal: 'legal'
} as const;

export type FindAdministrationInterestTypeEnum = typeof FindAdministrationInterestTypeEnum[keyof typeof FindAdministrationInterestTypeEnum];

/**
 * 
 * @export
 * @interface FindCreditCase
 */
export interface FindCreditCase {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof FindCreditCase
     */
    'id': number;
    /**
     * Date time of when the recourse is created.
     * @type {string}
     * @memberof FindCreditCase
     */
    'createdAt': string;
    /**
     * Date time of when the recourse last updated.
     * @type {string}
     * @memberof FindCreditCase
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'administrationId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'state': FindCreditCaseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'subState': FindCreditCaseSubStateEnum;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'invoicesPrice': number;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'casePrice': number;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'interestPrice': number;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'totalPriceOpen': number;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'baseCasePrice': number;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'postPaymentsPrice': number;
    /**
     * The amount the debtor paid before collection started.
     * @type {number}
     * @memberof FindCreditCase
     */
    'prePaymentsPrice': number;
    /**
     * Name of the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'debtorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'identificationNumber': string;
    /**
     * Required when `isCompany` = `true`.
     * @type {string}
     * @memberof FindCreditCase
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'gender'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'firstname'?: string | null;
    /**
     * Required when `isCompany` = `false`.
     * @type {string}
     * @memberof FindCreditCase
     */
    'lastname'?: string | null;
    /**
     * Email of the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindCreditCase
     */
    'isCompany': boolean;
    /**
     * Street of the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'streetName': string;
    /**
     * House number of the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'houseNumber': string;
    /**
     * Zipcode of the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'zipcode': string;
    /**
     * City of the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'city': string;
    /**
     * Country code of the debtor (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof FindCreditCase
     */
    'countryCode': string;
    /**
     * Language code to communicate in with the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'languageCode': FindCreditCaseLanguageCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'startedAt'?: string | null;
    /**
     * Should the credit case start with a pre-incasso.
     * @type {boolean}
     * @memberof FindCreditCase
     */
    'preIncasso'?: boolean;
    /**
     * 
     * @type {CreditCaseCreditor}
     * @memberof FindCreditCase
     */
    'creditor': CreditCaseCreditor;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'interestType': FindCreditCaseInterestTypeEnum;
    /**
     * Should the case be auto-forwarded to the selected bailiff.
     * @type {boolean}
     * @memberof FindCreditCase
     */
    'autoForward'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'origin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'currency': string;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'bailiffId'?: number | null;
    /**
     * The date the bailiff was assigned and informed about the case.
     * @type {string}
     * @memberof FindCreditCase
     */
    'bailiffAt'?: string | null;
    /**
     * 
     * @type {CompanyBailiff}
     * @memberof FindCreditCase
     */
    'bailiff'?: CompanyBailiff | null;
    /**
     * The date the case was forwarded to the bailiff.
     * @type {string}
     * @memberof FindCreditCase
     */
    'forwardedAt'?: string | null;
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof FindCreditCase
     */
    'invoices': Array<Invoice>;
    /**
     * 
     * @type {Array<CaseAttachment>}
     * @memberof FindCreditCase
     */
    'attachments': Array<CaseAttachment>;
    /**
     * 
     * @type {Array<PlannedPayment>}
     * @memberof FindCreditCase
     */
    'plannedPayments': Array<PlannedPayment>;
}

export const FindCreditCaseStateEnum = {
    Draft: 'draft',
    Paused: 'paused',
    Forwarded: 'forwarded',
    Closed: 'closed',
    Finished: 'finished',
    Paid: 'paid',
    Open: 'open'
} as const;

export type FindCreditCaseStateEnum = typeof FindCreditCaseStateEnum[keyof typeof FindCreditCaseStateEnum];
export const FindCreditCaseSubStateEnum = {
    Draft: 'draft',
    Notice: 'notice',
    Paid: 'paid',
    SecondNotice: 'second_notice',
    FormalNotice: 'formal_notice',
    PreJudiciary: 'pre_judiciary',
    PreDebtCollection: 'pre_debt_collection',
    Judiciary: 'judiciary',
    Finished: 'finished',
    PaymentPlan: 'payment_plan'
} as const;

export type FindCreditCaseSubStateEnum = typeof FindCreditCaseSubStateEnum[keyof typeof FindCreditCaseSubStateEnum];
export const FindCreditCaseLanguageCodeEnum = {
    Nl: 'nl',
    En: 'en'
} as const;

export type FindCreditCaseLanguageCodeEnum = typeof FindCreditCaseLanguageCodeEnum[keyof typeof FindCreditCaseLanguageCodeEnum];
export const FindCreditCaseInterestTypeEnum = {
    None: 'none',
    Custom: 'custom',
    Legal: 'legal'
} as const;

export type FindCreditCaseInterestTypeEnum = typeof FindCreditCaseInterestTypeEnum[keyof typeof FindCreditCaseInterestTypeEnum];

/**
 * 
 * @export
 * @interface ForgotPassword
 */
export interface ForgotPassword {
    /**
     * 
     * @type {string}
     * @memberof ForgotPassword
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface ForwardCreditCase
 */
export interface ForwardCreditCase {
    /**
     * 
     * @type {number}
     * @memberof ForwardCreditCase
     */
    'bailiffId': number;
}
/**
 * 
 * @export
 * @interface GroupStructure
 */
export interface GroupStructure {
    /**
     * 
     * @type {AffiliatedCompany}
     * @memberof GroupStructure
     */
    'immediate_parent'?: AffiliatedCompany | null;
    /**
     * 
     * @type {AffiliatedCompany}
     * @memberof GroupStructure
     */
    'ultimate_parent'?: AffiliatedCompany | null;
    /**
     * 
     * @type {SubsidiaryCompanies}
     * @memberof GroupStructure
     */
    'subsidiary_companies'?: SubsidiaryCompanies | null;
    /**
     * 
     * @type {AffiliatedCompanies}
     * @memberof GroupStructure
     */
    'affiliated_companies'?: AffiliatedCompanies | null;
}
/**
 * 
 * @export
 * @interface InterestStrategy
 */
export interface InterestStrategy {
    /**
     * 
     * @type {number}
     * @memberof InterestStrategy
     */
    'rate': number;
    /**
     * 
     * @type {boolean}
     * @memberof InterestStrategy
     */
    'cumulative'?: boolean;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof Invoice
     */
    'id': number;
    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof Invoice
     */
    'creditCaseId': number;
    /**
     * Date of the invoice.
     * @type {string}
     * @memberof Invoice
     */
    'invoiceDate': string;
    /**
     * Due date of the invoice.
     * @type {string}
     * @memberof Invoice
     */
    'dueDate': string;
    /**
     * ID/Reference of the invoice.
     * @type {string}
     * @memberof Invoice
     */
    'invoiceId': string;
    /**
     * Amount of the invoice including taxes.
     * @type {number}
     * @memberof Invoice
     */
    'priceInclTax': number;
    /**
     * Currency of the invoice.
     * @type {string}
     * @memberof Invoice
     */
    'currency': string;
    /**
     * File name of the invoice.
     * @type {string}
     * @memberof Invoice
     */
    'documentFileName'?: string | null;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof Login
     */
    'rememberComputer'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'client_id': string;
}
/**
 * 
 * @export
 * @interface LoginWithPin
 */
export interface LoginWithPin {
    /**
     * 
     * @type {string}
     * @memberof LoginWithPin
     */
    'identificationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof LoginWithPin
     */
    'identificationPin': string;
}
/**
 * 
 * @export
 * @interface MainActivity
 */
export interface MainActivity {
    /**
     * 
     * @type {string}
     * @memberof MainActivity
     */
    'activity_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainActivity
     */
    'activity_description'?: string | null;
}
/**
 * 
 * @export
 * @interface MainAddress
 */
export interface MainAddress {
    /**
     * 
     * @type {Address}
     * @memberof MainAddress
     */
    'address'?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof MainAddress
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainAddress
     */
    'telephone'?: string | null;
}
/**
 * 
 * @export
 * @interface Me
 */
export interface Me {
    /**
     * 
     * @type {string}
     * @memberof Me
     */
    'aId': string;
    /**
     * 
     * @type {string}
     * @memberof Me
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Me
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof Me
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof Me
     */
    'admin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Me
     */
    'owner': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Me
     */
    'receiveNewsletter': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Me
     */
    'receiveNotifications': boolean;
    /**
     * 
     * @type {Company}
     * @memberof Me
     */
    'company': Company;
    /**
     * 
     * @type {Array<Administration>}
     * @memberof Me
     */
    'administrations': Array<Administration>;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof Message
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface NewPlannedPayment
 */
export interface NewPlannedPayment {
    /**
     * 
     * @type {boolean}
     * @memberof NewPlannedPayment
     */
    'remission': boolean;
    /**
     * 
     * @type {string}
     * @memberof NewPlannedPayment
     */
    'dueDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewPlannedPayment
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface Note
 */
export interface Note {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof Note
     */
    'id': number;
    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof Note
     */
    'creditCaseId': number;
    /**
     * Message of the note.
     * @type {string}
     * @memberof Note
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface OtherAddresses
 */
export interface OtherAddresses {
    /**
     * 
     * @type {MainAddress}
     * @memberof OtherAddresses
     */
    'other_address'?: MainAddress | null;
}
/**
 * 
 * @export
 * @interface OtherFinancials
 */
export interface OtherFinancials {
    /**
     * 
     * @type {number}
     * @memberof OtherFinancials
     */
    'working_capital'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OtherFinancials
     */
    'net_worth'?: number | null;
}
/**
 * 
 * @export
 * @interface OtherInformation
 */
export interface OtherInformation {
    /**
     * 
     * @type {EmployeesInformation}
     * @memberof OtherInformation
     */
    'employees_information'?: EmployeesInformation | null;
}
/**
 * 
 * @export
 * @interface PageInfoType
 */
export interface PageInfoType {
    /**
     * true if paging forward and there are more records.
     * @type {boolean}
     * @memberof PageInfoType
     */
    'hasNextPage'?: boolean | null;
    /**
     * true if paging backwards and there are more records.
     * @type {boolean}
     * @memberof PageInfoType
     */
    'hasPreviousPage'?: boolean | null;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof Payment
     */
    'id': number;
    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof Payment
     */
    'creditCaseId': number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'paymentDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof Payment
     */
    'remission'?: boolean;
}
/**
 * 
 * @export
 * @interface PlannedPayment
 */
export interface PlannedPayment {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof PlannedPayment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PlannedPayment
     */
    'dueDate': string;
    /**
     * 
     * @type {number}
     * @memberof PlannedPayment
     */
    'amount': number;
    /**
     * 
     * @type {boolean}
     * @memberof PlannedPayment
     */
    'isOverdue': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlannedPayment
     */
    'isPaid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlannedPayment
     */
    'isRemission': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlannedPayment
     */
    'paidAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlannedPayment
     */
    'currency': string;
}
/**
 * 
 * @export
 * @interface PreviousDirectors
 */
export interface PreviousDirectors {
    /**
     * 
     * @type {Array<CreditCheckReportPreviousDirector>}
     * @memberof PreviousDirectors
     */
    'director'?: Array<CreditCheckReportPreviousDirector> | null;
}
/**
 * 
 * @export
 * @interface ProfitAndLoss
 */
export interface ProfitAndLoss {
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'operating_costs'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'operating_profit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'wages_and_salaries'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'depreciation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'financial_expenses'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'profit_before_tax'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'tax'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'profit_after_tax'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'retained_profit'?: number | null;
}
/**
 * 
 * @export
 * @interface Ratios
 */
export interface Ratios {
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'return_on_capital_employed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'return_on_total_assets_employed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'return_on_net_assets_employed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'current_ratio'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'liquidity_ratio_or_acid_test'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'current_debt_ratio'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'gearing'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'equity_in_percentage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'total_debt_ratio'?: number | null;
}
/**
 * 
 * @export
 * @interface Register
 */
export interface Register {
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'companyName': string;
    /**
     * Country code of the company (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof Register
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'passwordCheck': string;
    /**
     * 
     * @type {boolean}
     * @memberof Register
     */
    'newsletter'?: boolean;
}
/**
 * 
 * @export
 * @interface ResetPassword
 */
export interface ResetPassword {
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    'resetCode': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    'passwordCheck': string;
}
/**
 * 
 * @export
 * @interface SearchCreditCheckCompany
 */
export interface SearchCreditCheckCompany {
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompany
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompany
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompany
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompany
     */
    'vatNumber': string;
    /**
     * 
     * @type {SearchCreditCheckCompanyAddress}
     * @memberof SearchCreditCheckCompany
     */
    'address': SearchCreditCheckCompanyAddress;
    /**
     * 
     * @type {number}
     * @memberof SearchCreditCheckCompany
     */
    'olderReportId'?: number | null;
}
/**
 * 
 * @export
 * @interface SearchCreditCheckCompanyAddress
 */
export interface SearchCreditCheckCompanyAddress {
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompanyAddress
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompanyAddress
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompanyAddress
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompanyAddress
     */
    'city': string;
}
/**
 * 
 * @export
 * @interface SearchCreditCheckCompanyOffsetConnection
 */
export interface SearchCreditCheckCompanyOffsetConnection {
    /**
     * 
     * @type {PageInfoType}
     * @memberof SearchCreditCheckCompanyOffsetConnection
     */
    'pageInfo': PageInfoType;
    /**
     * Total amount of records.
     * @type {number}
     * @memberof SearchCreditCheckCompanyOffsetConnection
     */
    'totalCount': number;
    /**
     * Array of nodes.
     * @type {Array<SearchCreditCheckCompany>}
     * @memberof SearchCreditCheckCompanyOffsetConnection
     */
    'nodes': Array<SearchCreditCheckCompany>;
}
/**
 * 
 * @export
 * @interface ShareCapitalStructure
 */
export interface ShareCapitalStructure {
    /**
     * 
     * @type {string}
     * @memberof ShareCapitalStructure
     */
    'issued_share_capital'?: string | null;
    /**
     * 
     * @type {ShareHolders}
     * @memberof ShareCapitalStructure
     */
    'share_holders'?: ShareHolders | null;
}
/**
 * 
 * @export
 * @interface ShareHolder
 */
export interface ShareHolder {
    /**
     * 
     * @type {string}
     * @memberof ShareHolder
     */
    'name'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof ShareHolder
     */
    'address'?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof ShareHolder
     */
    'share_percent'?: string | null;
}
/**
 * 
 * @export
 * @interface ShareHolders
 */
export interface ShareHolders {
    /**
     * 
     * @type {Array<ShareHolder>}
     * @memberof ShareHolders
     */
    'share_holder'?: Array<ShareHolder> | null;
}
/**
 * 
 * @export
 * @interface SubsidiaryCompanies
 */
export interface SubsidiaryCompanies {
    /**
     * 
     * @type {Array<AffiliatedCompany>}
     * @memberof SubsidiaryCompanies
     */
    'subsidiary': Array<AffiliatedCompany>;
}
/**
 * 
 * @export
 * @interface SuggestedPlannedPayment
 */
export interface SuggestedPlannedPayment {
    /**
     * 
     * @type {boolean}
     * @memberof SuggestedPlannedPayment
     */
    'remission': boolean;
    /**
     * 
     * @type {string}
     * @memberof SuggestedPlannedPayment
     */
    'dueDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuggestedPlannedPayment
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof SuggestedPlannedPayment
     */
    'currency': string;
}
/**
 * 
 * @export
 * @interface ThirdPartyToken
 */
export interface ThirdPartyToken {
    /**
     * 
     * @type {number}
     * @memberof ThirdPartyToken
     */
    'id': number;
    /**
     * 
     * @type {ClientApplication}
     * @memberof ThirdPartyToken
     */
    'client': ClientApplication;
    /**
     * 
     * @type {boolean}
     * @memberof ThirdPartyToken
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof ThirdPartyToken
     */
    'invalidatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdPartyToken
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdPartyToken
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface TradingStyles
 */
export interface TradingStyles {
    /**
     * 
     * @type {Array<string>}
     * @memberof TradingStyles
     */
    'trading_name'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateAdministration
 */
export interface UpdateAdministration {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdministration
     */
    'companyName': string;
    /**
     * Street name of administration company.
     * @type {string}
     * @memberof UpdateAdministration
     */
    'streetName': string;
    /**
     * House number of administration company.
     * @type {string}
     * @memberof UpdateAdministration
     */
    'houseNumber': string;
    /**
     * Zipcode of administration company.
     * @type {string}
     * @memberof UpdateAdministration
     */
    'zipcode': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdministration
     */
    'city': string;
    /**
     * Country code of the administration (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof UpdateAdministration
     */
    'countryCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdministration
     */
    'bankAccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdministration
     */
    'bankAccountName'?: string | null;
    /**
     * Contact name to use on letters.
     * @type {string}
     * @memberof UpdateAdministration
     */
    'letterContactName'?: string | null;
    /**
     * Email address to use on letters.
     * @type {string}
     * @memberof UpdateAdministration
     */
    'letterEmail'?: string | null;
    /**
     * Phone number to use on letters.
     * @type {string}
     * @memberof UpdateAdministration
     */
    'letterPhone'?: string | null;
    /**
     * CoC number to use on letters.
     * @type {string}
     * @memberof UpdateAdministration
     */
    'letterKvkNumber'?: string | null;
    /**
     * Vat number to use on letters.
     * @type {string}
     * @memberof UpdateAdministration
     */
    'letterVatNumber'?: string | null;
    /**
     * Email address for notifications.
     * @type {string}
     * @memberof UpdateAdministration
     */
    'contactEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdministration
     */
    'interestType': UpdateAdministrationInterestTypeEnum;
    /**
     * 
     * @type {InterestStrategy}
     * @memberof UpdateAdministration
     */
    'interestStrategy'?: InterestStrategy | null;
}

export const UpdateAdministrationInterestTypeEnum = {
    None: 'none',
    Custom: 'custom',
    Legal: 'legal'
} as const;

export type UpdateAdministrationInterestTypeEnum = typeof UpdateAdministrationInterestTypeEnum[keyof typeof UpdateAdministrationInterestTypeEnum];

/**
 * 
 * @export
 * @interface UpdateCaseActionFailedReason
 */
export interface UpdateCaseActionFailedReason {
    /**
     * 
     * @type {string}
     * @memberof UpdateCaseActionFailedReason
     */
    'reason': UpdateCaseActionFailedReasonReasonEnum;
}

export const UpdateCaseActionFailedReasonReasonEnum = {
    Unknown: 'unknown',
    Refused: 'refused',
    Left: 'left',
    NotCollected: 'not_collected',
    IncompleteAddress: 'incomplete_address',
    UnknownAddress: 'unknown_address',
    Deceased: 'deceased'
} as const;

export type UpdateCaseActionFailedReasonReasonEnum = typeof UpdateCaseActionFailedReasonReasonEnum[keyof typeof UpdateCaseActionFailedReasonReasonEnum];

/**
 * 
 * @export
 * @interface UpdateCompany
 */
export interface UpdateCompany {
    /**
     * Name of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'companyName'?: string | null;
    /**
     * Street name of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'streetName'?: string | null;
    /**
     * House number of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'houseNumber'?: string | null;
    /**
     * Zipcode of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'zipcode'?: string | null;
    /**
     * City of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'city'?: string | null;
    /**
     * Country code of the company (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof UpdateCompany
     */
    'countryCode'?: string | null;
    /**
     * CoC number of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'kvkNumber'?: string | null;
    /**
     * Phone number of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'phoneNumber'?: string;
    /**
     * IBAN of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'bankAccountNumber'?: string | null;
    /**
     * Name of the bank account.
     * @type {string}
     * @memberof UpdateCompany
     */
    'bankAccountName'?: string | null;
    /**
     * Contact name to use on letters.
     * @type {string}
     * @memberof UpdateCompany
     */
    'letterContactName'?: string | null;
    /**
     * Email address to use on letters.
     * @type {string}
     * @memberof UpdateCompany
     */
    'letterEmail'?: string | null;
    /**
     * Phone number to use on letters.
     * @type {string}
     * @memberof UpdateCompany
     */
    'letterPhone'?: string | null;
    /**
     * CoC number to use on letters.
     * @type {string}
     * @memberof UpdateCompany
     */
    'letterKvkNumber'?: string | null;
    /**
     * Vat number to use on letters.
     * @type {string}
     * @memberof UpdateCompany
     */
    'letterVatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompany
     */
    'logoFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompany
     */
    'logoContentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompany
     */
    'logoUpdatedAt'?: string | null;
    /**
     * Auto-forward new credit cases to the selected bailiff.
     * @type {boolean}
     * @memberof UpdateCompany
     */
    'autoForward'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompany
     */
    'interestType'?: UpdateCompanyInterestTypeEnum;
    /**
     * 
     * @type {InterestStrategy}
     * @memberof UpdateCompany
     */
    'interestStrategy'?: InterestStrategy | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompany
     */
    'bailiffId'?: number | null;
}

export const UpdateCompanyInterestTypeEnum = {
    None: 'none',
    Custom: 'custom',
    Legal: 'legal'
} as const;

export type UpdateCompanyInterestTypeEnum = typeof UpdateCompanyInterestTypeEnum[keyof typeof UpdateCompanyInterestTypeEnum];

/**
 * 
 * @export
 * @interface UpdateCreditCase
 */
export interface UpdateCreditCase {
    /**
     * 
     * @type {number}
     * @memberof UpdateCreditCase
     */
    'administrationId'?: number | null;
    /**
     * Required when `isCompany` = `true`.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCreditCase
     */
    'gender'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'firstname'?: string | null;
    /**
     * Required when `isCompany` = `false`.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'lastname'?: string | null;
    /**
     * Email of the debtor.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCreditCase
     */
    'isCompany'?: boolean;
    /**
     * Street of the debtor.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'streetName'?: string;
    /**
     * House number of the debtor.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'houseNumber'?: string;
    /**
     * Zipcode of the debtor.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'zipcode'?: string;
    /**
     * City of the debtor.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'city'?: string;
    /**
     * Country code of the debtor (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'countryCode'?: string;
    /**
     * Language code to communicate in with the debtor.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'languageCode'?: UpdateCreditCaseLanguageCodeEnum;
    /**
     * Should the credit case start with a pre-incasso.
     * @type {boolean}
     * @memberof UpdateCreditCase
     */
    'preIncasso'?: boolean;
    /**
     * Should the case be auto-forwarded to the selected bailiff.
     * @type {boolean}
     * @memberof UpdateCreditCase
     */
    'autoForward'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'interestType'?: UpdateCreditCaseInterestTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateCreditCase
     */
    'bailiffId'?: number | null;
}

export const UpdateCreditCaseLanguageCodeEnum = {
    Nl: 'nl',
    En: 'en'
} as const;

export type UpdateCreditCaseLanguageCodeEnum = typeof UpdateCreditCaseLanguageCodeEnum[keyof typeof UpdateCreditCaseLanguageCodeEnum];
export const UpdateCreditCaseInterestTypeEnum = {
    None: 'none',
    Legal: 'legal'
} as const;

export type UpdateCreditCaseInterestTypeEnum = typeof UpdateCreditCaseInterestTypeEnum[keyof typeof UpdateCreditCaseInterestTypeEnum];

/**
 * 
 * @export
 * @interface UpdateDebtor
 */
export interface UpdateDebtor {
    /**
     * Required when `isCompany` = `true`.
     * @type {string}
     * @memberof UpdateDebtor
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDebtor
     */
    'firstname'?: string | null;
    /**
     * Required when `isCompany` = `false`.
     * @type {string}
     * @memberof UpdateDebtor
     */
    'lastname'?: string | null;
    /**
     * Email of the debtor.
     * @type {string}
     * @memberof UpdateDebtor
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDebtor
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDebtor
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDebtor
     */
    'isCompany': boolean;
    /**
     * Street of the debtor.
     * @type {string}
     * @memberof UpdateDebtor
     */
    'streetName': string;
    /**
     * House number of the debtor.
     * @type {string}
     * @memberof UpdateDebtor
     */
    'houseNumber': string;
    /**
     * Zipcode of the debtor.
     * @type {string}
     * @memberof UpdateDebtor
     */
    'zipcode': string;
    /**
     * City of the debtor.
     * @type {string}
     * @memberof UpdateDebtor
     */
    'city': string;
    /**
     * Country code of the debtor (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof UpdateDebtor
     */
    'countryCode': string;
}
/**
 * 
 * @export
 * @interface UpdateInvoice
 */
export interface UpdateInvoice {
    /**
     * Date of the invoice.
     * @type {string}
     * @memberof UpdateInvoice
     */
    'invoiceDate'?: string;
    /**
     * Due date of the invoice.
     * @type {string}
     * @memberof UpdateInvoice
     */
    'dueDate'?: string;
    /**
     * ID/Reference of the invoice.
     * @type {string}
     * @memberof UpdateInvoice
     */
    'invoiceId'?: string;
    /**
     * Amount of the invoice including taxes.
     * @type {number}
     * @memberof UpdateInvoice
     */
    'priceInclTax'?: number;
}
/**
 * 
 * @export
 * @interface UpdateMe
 */
export interface UpdateMe {
    /**
     * 
     * @type {string}
     * @memberof UpdateMe
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMe
     */
    'receiveNotifications': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMe
     */
    'receiveNewsletter': boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'owner': boolean;
}
/**
 * 
 * @export
 * @interface UserConnection
 */
export interface UserConnection {
    /**
     * 
     * @type {PageInfoType}
     * @memberof UserConnection
     */
    'pageInfo': PageInfoType;
    /**
     * Total amount of records.
     * @type {number}
     * @memberof UserConnection
     */
    'totalCount': number;
    /**
     * Array of nodes.
     * @type {Array<User>}
     * @memberof UserConnection
     */
    'nodes': Array<User>;
}
/**
 * 
 * @export
 * @interface Websites
 */
export interface Websites {
    /**
     * 
     * @type {Array<string>}
     * @memberof Websites
     */
    'website'?: Array<string> | null;
}

/**
 * BailiffApi - axios parameter creator
 * @export
 */
export const BailiffApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bailiffsDownloadLogo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bailiffsDownloadLogo', 'id', id)
            const localVarPath = `/v2.0/bailiffs/{id}/logo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BailiffApi - functional programming interface
 * @export
 */
export const BailiffApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BailiffApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bailiffsDownloadLogo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bailiffsDownloadLogo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BailiffApi - factory interface
 * @export
 */
export const BailiffApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BailiffApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {BailiffApiBailiffsDownloadLogoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bailiffsDownloadLogo(requestParameters: BailiffApiBailiffsDownloadLogoRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.bailiffsDownloadLogo(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bailiffsDownloadLogo operation in BailiffApi.
 * @export
 * @interface BailiffApiBailiffsDownloadLogoRequest
 */
export interface BailiffApiBailiffsDownloadLogoRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof BailiffApiBailiffsDownloadLogo
     */
    readonly id: number
}

/**
 * BailiffApi - object-oriented interface
 * @export
 * @class BailiffApi
 * @extends {BaseAPI}
 */
export class BailiffApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {BailiffApiBailiffsDownloadLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BailiffApi
     */
    public bailiffsDownloadLogo(requestParameters: BailiffApiBailiffsDownloadLogoRequest, options?: AxiosRequestConfig) {
        return BailiffApiFp(this.configuration).bailiffsDownloadLogo(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BailiffsApi - axios parameter creator
 * @export
 */
export const BailiffsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all bailiffs.
         * @summary 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bailiffsQueryMany: async (limit?: number | null, offset?: number | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/bailiffs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BailiffsApi - functional programming interface
 * @export
 */
export const BailiffsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BailiffsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all bailiffs.
         * @summary 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bailiffsQueryMany(limit?: number | null, offset?: number | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BailiffConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bailiffsQueryMany(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BailiffsApi - factory interface
 * @export
 */
export const BailiffsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BailiffsApiFp(configuration)
    return {
        /**
         * Get all bailiffs.
         * @summary 
         * @param {BailiffsApiBailiffsQueryManyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bailiffsQueryMany(requestParameters: BailiffsApiBailiffsQueryManyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BailiffConnection> {
            return localVarFp.bailiffsQueryMany(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bailiffsQueryMany operation in BailiffsApi.
 * @export
 * @interface BailiffsApiBailiffsQueryManyRequest
 */
export interface BailiffsApiBailiffsQueryManyRequest {
    /**
     * 
     * @type {number}
     * @memberof BailiffsApiBailiffsQueryMany
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof BailiffsApiBailiffsQueryMany
     */
    readonly offset?: number | null
}

/**
 * BailiffsApi - object-oriented interface
 * @export
 * @class BailiffsApi
 * @extends {BaseAPI}
 */
export class BailiffsApi extends BaseAPI {
    /**
     * Get all bailiffs.
     * @summary 
     * @param {BailiffsApiBailiffsQueryManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BailiffsApi
     */
    public bailiffsQueryMany(requestParameters: BailiffsApiBailiffsQueryManyRequest = {}, options?: AxiosRequestConfig) {
        return BailiffsApiFp(this.configuration).bailiffsQueryMany(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CheckoutApi - axios parameter creator
 * @export
 */
export const CheckoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} provider 
         * @param {Cart} cart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkout: async (provider: string, cart: Cart, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('checkout', 'provider', provider)
            // verify required parameter 'cart' is not null or undefined
            assertParamExists('checkout', 'cart', cart)
            const localVarPath = `/v2.0/checkout/{provider}`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cart, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckoutApi - functional programming interface
 * @export
 */
export const CheckoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckoutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} provider 
         * @param {Cart} cart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkout(provider: string, cart: Cart, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckoutComplete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkout(provider, cart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckoutApi - factory interface
 * @export
 */
export const CheckoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckoutApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CheckoutApiCheckoutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkout(requestParameters: CheckoutApiCheckoutRequest, options?: AxiosRequestConfig): AxiosPromise<CheckoutComplete> {
            return localVarFp.checkout(requestParameters.provider, requestParameters.cart, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for checkout operation in CheckoutApi.
 * @export
 * @interface CheckoutApiCheckoutRequest
 */
export interface CheckoutApiCheckoutRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckoutApiCheckout
     */
    readonly provider: string

    /**
     * 
     * @type {Cart}
     * @memberof CheckoutApiCheckout
     */
    readonly cart: Cart
}

/**
 * CheckoutApi - object-oriented interface
 * @export
 * @class CheckoutApi
 * @extends {BaseAPI}
 */
export class CheckoutApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CheckoutApiCheckoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public checkout(requestParameters: CheckoutApiCheckoutRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).checkout(requestParameters.provider, requestParameters.cart, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationDownloadLogo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('administrationDownloadLogo', 'id', id)
            const localVarPath = `/v2.0/company/administrations/{id}/logo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationUploadLogo: async (id: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('administrationUploadLogo', 'id', id)
            const localVarPath = `/v2.0/company/administrations/{id}/logo/upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new administration.
         * @summary 
         * @param {CreateAdministration} createAdministration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationsCreateOne: async (createAdministration: CreateAdministration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdministration' is not null or undefined
            assertParamExists('administrationsCreateOne', 'createAdministration', createAdministration)
            const localVarPath = `/v2.0/company/administrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdministration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one administration.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationsDeleteOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('administrationsDeleteOne', 'id', id)
            const localVarPath = `/v2.0/company/administrations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one administration.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationsFindById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('administrationsFindById', 'id', id)
            const localVarPath = `/v2.0/company/administrations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one administration.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {UpdateAdministration} updateAdministration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationsUpdateOne: async (id: number, updateAdministration: UpdateAdministration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('administrationsUpdateOne', 'id', id)
            // verify required parameter 'updateAdministration' is not null or undefined
            assertParamExists('administrationsUpdateOne', 'updateAdministration', updateAdministration)
            const localVarPath = `/v2.0/company/administrations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdministration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDownloadLogo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/company/logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update your company
         * @summary 
         * @param {UpdateCompany} updateCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdate: async (updateCompany: UpdateCompany, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompany' is not null or undefined
            assertParamExists('companyUpdate', 'updateCompany', updateCompany)
            const localVarPath = `/v2.0/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompany, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUploadLogo: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/company/logo/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thirdPartyAccessTokens: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/company/third-party/access-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thirdPartyRevokeAccessToken: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('thirdPartyRevokeAccessToken', 'id', id)
            const localVarPath = `/v2.0/company/third-party/access-tokens/revoke/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new user.
         * @summary 
         * @param {CreateUser} createUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreateOne: async (createUser: CreateUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUser' is not null or undefined
            assertParamExists('usersCreateOne', 'createUser', createUser)
            const localVarPath = `/v2.0/company/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one user.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersDeleteOne', 'id', id)
            const localVarPath = `/v2.0/company/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all users.
         * @summary 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersQueryMany: async (limit?: number | null, offset?: number | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/company/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administrationDownloadLogo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administrationDownloadLogo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administrationUploadLogo(id: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Administration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administrationUploadLogo(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new administration.
         * @summary 
         * @param {CreateAdministration} createAdministration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administrationsCreateOne(createAdministration: CreateAdministration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Administration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administrationsCreateOne(createAdministration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one administration.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administrationsDeleteOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Administration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administrationsDeleteOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one administration.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administrationsFindById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAdministration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administrationsFindById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one administration.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {UpdateAdministration} updateAdministration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administrationsUpdateOne(id: number, updateAdministration: UpdateAdministration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Administration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administrationsUpdateOne(id, updateAdministration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDownloadLogo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyDownloadLogo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update your company
         * @summary 
         * @param {UpdateCompany} updateCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUpdate(updateCompany: UpdateCompany, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyUpdate(updateCompany, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUploadLogo(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyUploadLogo(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async thirdPartyAccessTokens(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThirdPartyToken>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.thirdPartyAccessTokens(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async thirdPartyRevokeAccessToken(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdPartyToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.thirdPartyRevokeAccessToken(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new user.
         * @summary 
         * @param {CreateUser} createUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCreateOne(createUser: CreateUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCreateOne(createUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one user.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDeleteOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDeleteOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all users.
         * @summary 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersQueryMany(limit?: number | null, offset?: number | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersQueryMany(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CompanyApiAdministrationDownloadLogoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationDownloadLogo(requestParameters: CompanyApiAdministrationDownloadLogoRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.administrationDownloadLogo(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CompanyApiAdministrationUploadLogoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationUploadLogo(requestParameters: CompanyApiAdministrationUploadLogoRequest, options?: AxiosRequestConfig): AxiosPromise<Administration> {
            return localVarFp.administrationUploadLogo(requestParameters.id, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new administration.
         * @summary 
         * @param {CompanyApiAdministrationsCreateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationsCreateOne(requestParameters: CompanyApiAdministrationsCreateOneRequest, options?: AxiosRequestConfig): AxiosPromise<Administration> {
            return localVarFp.administrationsCreateOne(requestParameters.createAdministration, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one administration.
         * @summary 
         * @param {CompanyApiAdministrationsDeleteOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationsDeleteOne(requestParameters: CompanyApiAdministrationsDeleteOneRequest, options?: AxiosRequestConfig): AxiosPromise<Administration> {
            return localVarFp.administrationsDeleteOne(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one administration.
         * @summary 
         * @param {CompanyApiAdministrationsFindByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationsFindById(requestParameters: CompanyApiAdministrationsFindByIdRequest, options?: AxiosRequestConfig): AxiosPromise<FindAdministration> {
            return localVarFp.administrationsFindById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one administration.
         * @summary 
         * @param {CompanyApiAdministrationsUpdateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationsUpdateOne(requestParameters: CompanyApiAdministrationsUpdateOneRequest, options?: AxiosRequestConfig): AxiosPromise<Administration> {
            return localVarFp.administrationsUpdateOne(requestParameters.id, requestParameters.updateAdministration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDownloadLogo(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.companyDownloadLogo(options).then((request) => request(axios, basePath));
        },
        /**
         * Update your company
         * @summary 
         * @param {CompanyApiCompanyUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdate(requestParameters: CompanyApiCompanyUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.companyUpdate(requestParameters.updateCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CompanyApiCompanyUploadLogoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUploadLogo(requestParameters: CompanyApiCompanyUploadLogoRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.companyUploadLogo(requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thirdPartyAccessTokens(options?: AxiosRequestConfig): AxiosPromise<Array<ThirdPartyToken>> {
            return localVarFp.thirdPartyAccessTokens(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CompanyApiThirdPartyRevokeAccessTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thirdPartyRevokeAccessToken(requestParameters: CompanyApiThirdPartyRevokeAccessTokenRequest, options?: AxiosRequestConfig): AxiosPromise<ThirdPartyToken> {
            return localVarFp.thirdPartyRevokeAccessToken(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new user.
         * @summary 
         * @param {CompanyApiUsersCreateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreateOne(requestParameters: CompanyApiUsersCreateOneRequest, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersCreateOne(requestParameters.createUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one user.
         * @summary 
         * @param {CompanyApiUsersDeleteOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteOne(requestParameters: CompanyApiUsersDeleteOneRequest, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersDeleteOne(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all users.
         * @summary 
         * @param {CompanyApiUsersQueryManyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersQueryMany(requestParameters: CompanyApiUsersQueryManyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UserConnection> {
            return localVarFp.usersQueryMany(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for administrationDownloadLogo operation in CompanyApi.
 * @export
 * @interface CompanyApiAdministrationDownloadLogoRequest
 */
export interface CompanyApiAdministrationDownloadLogoRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiAdministrationDownloadLogo
     */
    readonly id: number
}

/**
 * Request parameters for administrationUploadLogo operation in CompanyApi.
 * @export
 * @interface CompanyApiAdministrationUploadLogoRequest
 */
export interface CompanyApiAdministrationUploadLogoRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiAdministrationUploadLogo
     */
    readonly id: number

    /**
     * 
     * @type {File}
     * @memberof CompanyApiAdministrationUploadLogo
     */
    readonly file?: File
}

/**
 * Request parameters for administrationsCreateOne operation in CompanyApi.
 * @export
 * @interface CompanyApiAdministrationsCreateOneRequest
 */
export interface CompanyApiAdministrationsCreateOneRequest {
    /**
     * 
     * @type {CreateAdministration}
     * @memberof CompanyApiAdministrationsCreateOne
     */
    readonly createAdministration: CreateAdministration
}

/**
 * Request parameters for administrationsDeleteOne operation in CompanyApi.
 * @export
 * @interface CompanyApiAdministrationsDeleteOneRequest
 */
export interface CompanyApiAdministrationsDeleteOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CompanyApiAdministrationsDeleteOne
     */
    readonly id: number
}

/**
 * Request parameters for administrationsFindById operation in CompanyApi.
 * @export
 * @interface CompanyApiAdministrationsFindByIdRequest
 */
export interface CompanyApiAdministrationsFindByIdRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CompanyApiAdministrationsFindById
     */
    readonly id: number
}

/**
 * Request parameters for administrationsUpdateOne operation in CompanyApi.
 * @export
 * @interface CompanyApiAdministrationsUpdateOneRequest
 */
export interface CompanyApiAdministrationsUpdateOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CompanyApiAdministrationsUpdateOne
     */
    readonly id: number

    /**
     * 
     * @type {UpdateAdministration}
     * @memberof CompanyApiAdministrationsUpdateOne
     */
    readonly updateAdministration: UpdateAdministration
}

/**
 * Request parameters for companyUpdate operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyUpdateRequest
 */
export interface CompanyApiCompanyUpdateRequest {
    /**
     * 
     * @type {UpdateCompany}
     * @memberof CompanyApiCompanyUpdate
     */
    readonly updateCompany: UpdateCompany
}

/**
 * Request parameters for companyUploadLogo operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyUploadLogoRequest
 */
export interface CompanyApiCompanyUploadLogoRequest {
    /**
     * 
     * @type {File}
     * @memberof CompanyApiCompanyUploadLogo
     */
    readonly file?: File
}

/**
 * Request parameters for thirdPartyRevokeAccessToken operation in CompanyApi.
 * @export
 * @interface CompanyApiThirdPartyRevokeAccessTokenRequest
 */
export interface CompanyApiThirdPartyRevokeAccessTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyApiThirdPartyRevokeAccessToken
     */
    readonly id: string
}

/**
 * Request parameters for usersCreateOne operation in CompanyApi.
 * @export
 * @interface CompanyApiUsersCreateOneRequest
 */
export interface CompanyApiUsersCreateOneRequest {
    /**
     * 
     * @type {CreateUser}
     * @memberof CompanyApiUsersCreateOne
     */
    readonly createUser: CreateUser
}

/**
 * Request parameters for usersDeleteOne operation in CompanyApi.
 * @export
 * @interface CompanyApiUsersDeleteOneRequest
 */
export interface CompanyApiUsersDeleteOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CompanyApiUsersDeleteOne
     */
    readonly id: number
}

/**
 * Request parameters for usersQueryMany operation in CompanyApi.
 * @export
 * @interface CompanyApiUsersQueryManyRequest
 */
export interface CompanyApiUsersQueryManyRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiUsersQueryMany
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof CompanyApiUsersQueryMany
     */
    readonly offset?: number | null
}

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CompanyApiAdministrationDownloadLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public administrationDownloadLogo(requestParameters: CompanyApiAdministrationDownloadLogoRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).administrationDownloadLogo(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CompanyApiAdministrationUploadLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public administrationUploadLogo(requestParameters: CompanyApiAdministrationUploadLogoRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).administrationUploadLogo(requestParameters.id, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new administration.
     * @summary 
     * @param {CompanyApiAdministrationsCreateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public administrationsCreateOne(requestParameters: CompanyApiAdministrationsCreateOneRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).administrationsCreateOne(requestParameters.createAdministration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one administration.
     * @summary 
     * @param {CompanyApiAdministrationsDeleteOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public administrationsDeleteOne(requestParameters: CompanyApiAdministrationsDeleteOneRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).administrationsDeleteOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one administration.
     * @summary 
     * @param {CompanyApiAdministrationsFindByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public administrationsFindById(requestParameters: CompanyApiAdministrationsFindByIdRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).administrationsFindById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one administration.
     * @summary 
     * @param {CompanyApiAdministrationsUpdateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public administrationsUpdateOne(requestParameters: CompanyApiAdministrationsUpdateOneRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).administrationsUpdateOne(requestParameters.id, requestParameters.updateAdministration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyDownloadLogo(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyDownloadLogo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update your company
     * @summary 
     * @param {CompanyApiCompanyUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUpdate(requestParameters: CompanyApiCompanyUpdateRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyUpdate(requestParameters.updateCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CompanyApiCompanyUploadLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUploadLogo(requestParameters: CompanyApiCompanyUploadLogoRequest = {}, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyUploadLogo(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public thirdPartyAccessTokens(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).thirdPartyAccessTokens(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CompanyApiThirdPartyRevokeAccessTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public thirdPartyRevokeAccessToken(requestParameters: CompanyApiThirdPartyRevokeAccessTokenRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).thirdPartyRevokeAccessToken(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new user.
     * @summary 
     * @param {CompanyApiUsersCreateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public usersCreateOne(requestParameters: CompanyApiUsersCreateOneRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).usersCreateOne(requestParameters.createUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one user.
     * @summary 
     * @param {CompanyApiUsersDeleteOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public usersDeleteOne(requestParameters: CompanyApiUsersDeleteOneRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).usersDeleteOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all users.
     * @summary 
     * @param {CompanyApiUsersQueryManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public usersQueryMany(requestParameters: CompanyApiUsersQueryManyRequest = {}, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).usersQueryMany(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CreditCasesApi - axios parameter creator
 * @export
 */
export const CreditCasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseActionGetEmail: async (id: number, creditCaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseActionGetEmail', 'id', id)
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('caseActionGetEmail', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/emails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseActionGetLetter: async (id: number, creditCaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseActionGetLetter', 'id', id)
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('caseActionGetLetter', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/letters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {UpdateCaseActionFailedReason} updateCaseActionFailedReason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseActionUpdateFailedReason: async (id: number, creditCaseId: number, updateCaseActionFailedReason: UpdateCaseActionFailedReason, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseActionUpdateFailedReason', 'id', id)
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('caseActionUpdateFailedReason', 'creditCaseId', creditCaseId)
            // verify required parameter 'updateCaseActionFailedReason' is not null or undefined
            assertParamExists('caseActionUpdateFailedReason', 'updateCaseActionFailedReason', updateCaseActionFailedReason)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/timeline/action/{id}/failed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCaseActionFailedReason, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAttachmentDeleteOne: async (id: number, creditCaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseAttachmentDeleteOne', 'id', id)
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('caseAttachmentDeleteOne', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/case-attachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAttachmentDownload: async (id: number, creditCaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseAttachmentDownload', 'id', id)
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('caseAttachmentDownload', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/case-attachment/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAttachmentUpload: async (creditCaseId: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('caseAttachmentUpload', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/case-attachment`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAttachmentUploadPreIncasso: async (creditCaseId: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('caseAttachmentUploadPreIncasso', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/case-attachment/pre-incasso`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseTimelineStepQueryMany: async (creditCaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('caseTimelineStepQueryMany', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/timeline`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create one credit case.
         * @summary 
         * @param {CreateCreditCase} createCreditCase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesCreateOne: async (createCreditCase: CreateCreditCase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCreditCase' is not null or undefined
            assertParamExists('creditCasesCreateOne', 'createCreditCase', createCreditCase)
            const localVarPath = `/v2.0/credit-cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCreditCase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesDeleteOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('creditCasesDeleteOne', 'id', id)
            const localVarPath = `/v2.0/credit-cases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesFindById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('creditCasesFindById', 'id', id)
            const localVarPath = `/v2.0/credit-cases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {ForwardCreditCase} forwardCreditCase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesForwardOne: async (creditCaseId: number, forwardCreditCase: ForwardCreditCase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('creditCasesForwardOne', 'creditCaseId', creditCaseId)
            // verify required parameter 'forwardCreditCase' is not null or undefined
            assertParamExists('creditCasesForwardOne', 'forwardCreditCase', forwardCreditCase)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/forward`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forwardCreditCase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all credit cases.
         * @summary 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {string | null} [query] 
         * @param {CreditCasesQueryManyActionEnum} [action] 
         * @param {CreditCasesQueryManyStateEnum} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesQueryMany: async (limit?: number | null, offset?: number | null, query?: string | null, action?: CreditCasesQueryManyActionEnum, state?: CreditCasesQueryManyStateEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/credit-cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {UpdateCreditCase} updateCreditCase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesUpdateOne: async (id: number, updateCreditCase: UpdateCreditCase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('creditCasesUpdateOne', 'id', id)
            // verify required parameter 'updateCreditCase' is not null or undefined
            assertParamExists('creditCasesUpdateOne', 'updateCreditCase', updateCreditCase)
            const localVarPath = `/v2.0/credit-cases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCreditCase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {CreditCasesUpdateStateStateEnum} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesUpdateState: async (creditCaseId: number, state: CreditCasesUpdateStateStateEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('creditCasesUpdateState', 'creditCaseId', creditCaseId)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('creditCasesUpdateState', 'state', state)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/{state}`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)))
                .replace(`{${"state"}}`, encodeURIComponent(String(state)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update debtor info for active credit case.
         * @summary 
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {UpdateDebtor} updateDebtor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debtorsUpdateOne: async (creditCaseId: number, updateDebtor: UpdateDebtor, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('debtorsUpdateOne', 'creditCaseId', creditCaseId)
            // verify required parameter 'updateDebtor' is not null or undefined
            assertParamExists('debtorsUpdateOne', 'updateDebtor', updateDebtor)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/debtor`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDebtor, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add one invoice to the draft credit case.
         * @summary 
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {CreateInvoice} createInvoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesCreateOne: async (creditCaseId: number, createInvoice: CreateInvoice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('invoicesCreateOne', 'creditCaseId', creditCaseId)
            // verify required parameter 'createInvoice' is not null or undefined
            assertParamExists('invoicesCreateOne', 'createInvoice', createInvoice)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/invoices`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvoice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one invoice of a draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesDeleteOne: async (id: number, creditCaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoicesDeleteOne', 'id', id)
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('invoicesDeleteOne', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesDownload: async (id: number, creditCaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoicesDownload', 'id', id)
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('invoicesDownload', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/invoices/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one invoice of a draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {UpdateInvoice} updateInvoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesUpdateOne: async (id: number, creditCaseId: number, updateInvoice: UpdateInvoice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoicesUpdateOne', 'id', id)
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('invoicesUpdateOne', 'creditCaseId', creditCaseId)
            // verify required parameter 'updateInvoice' is not null or undefined
            assertParamExists('invoicesUpdateOne', 'updateInvoice', updateInvoice)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvoice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesUpload: async (id: number, creditCaseId: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoicesUpload', 'id', id)
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('invoicesUpload', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/invoices/{id}/upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add one message to credit case.
         * @summary 
         * @param {number} creditCaseId 
         * @param {CreateMessage} createMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesCreateOne: async (creditCaseId: number, createMessage: CreateMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('messagesCreateOne', 'creditCaseId', creditCaseId)
            // verify required parameter 'createMessage' is not null or undefined
            assertParamExists('messagesCreateOne', 'createMessage', createMessage)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/messages`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add one note to the credit case.
         * @summary 
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {CreateNote} createNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesCreateOne: async (creditCaseId: number, createNote: CreateNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('notesCreateOne', 'creditCaseId', creditCaseId)
            // verify required parameter 'createNote' is not null or undefined
            assertParamExists('notesCreateOne', 'createNote', createNote)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/notes`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one note of a credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesDeleteOne: async (id: number, creditCaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notesDeleteOne', 'id', id)
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('notesDeleteOne', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {CreatePaymentPlan} createPaymentPlan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanCreateOne: async (creditCaseId: number, createPaymentPlan: CreatePaymentPlan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('paymentPlanCreateOne', 'creditCaseId', creditCaseId)
            // verify required parameter 'createPaymentPlan' is not null or undefined
            assertParamExists('paymentPlanCreateOne', 'createPaymentPlan', createPaymentPlan)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/payment-plan`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentPlan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanDeleteOne: async (creditCaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('paymentPlanDeleteOne', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/payment-plan`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {number} amount 
         * @param {number} terms 
         * @param {PaymentPlanSuggestOneTypeEnum} type 
         * @param {string} dueDate The date the payment plan starts on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanSuggestOne: async (creditCaseId: number, amount: number, terms: number, type: PaymentPlanSuggestOneTypeEnum, dueDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('paymentPlanSuggestOne', 'creditCaseId', creditCaseId)
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('paymentPlanSuggestOne', 'amount', amount)
            // verify required parameter 'terms' is not null or undefined
            assertParamExists('paymentPlanSuggestOne', 'terms', terms)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('paymentPlanSuggestOne', 'type', type)
            // verify required parameter 'dueDate' is not null or undefined
            assertParamExists('paymentPlanSuggestOne', 'dueDate', dueDate)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/payment-plan/suggest`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (terms !== undefined) {
                localVarQueryParameter['terms'] = terms;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (dueDate !== undefined) {
                localVarQueryParameter['dueDate'] = (dueDate as any instanceof Date) ?
                    (dueDate as any).toISOString() :
                    dueDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add one payment to credit case.
         * @summary 
         * @param {number} creditCaseId 
         * @param {CreatePayment} createPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCreateOne: async (creditCaseId: number, createPayment: CreatePayment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('paymentsCreateOne', 'creditCaseId', creditCaseId)
            // verify required parameter 'createPayment' is not null or undefined
            assertParamExists('paymentsCreateOne', 'createPayment', createPayment)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/payments`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPayment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one payment from a credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsDeleteOne: async (id: number, creditCaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentsDeleteOne', 'id', id)
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('paymentsDeleteOne', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/payments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditCasesApi - functional programming interface
 * @export
 */
export const CreditCasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreditCasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseActionGetEmail(id: number, creditCaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseActionGetEmail(id, creditCaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseActionGetLetter(id: number, creditCaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseActionGetLetter(id, creditCaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {UpdateCaseActionFailedReason} updateCaseActionFailedReason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseActionUpdateFailedReason(id: number, creditCaseId: number, updateCaseActionFailedReason: UpdateCaseActionFailedReason, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseActionUpdateFailedReason(id, creditCaseId, updateCaseActionFailedReason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseAttachmentDeleteOne(id: number, creditCaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseAttachmentDeleteOne(id, creditCaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseAttachmentDownload(id: number, creditCaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseAttachmentDownload(id, creditCaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseAttachmentUpload(creditCaseId: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseAttachmentUpload(creditCaseId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseAttachmentUploadPreIncasso(creditCaseId: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseAttachmentUploadPreIncasso(creditCaseId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseTimelineStepQueryMany(creditCaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseTimelineConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseTimelineStepQueryMany(creditCaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create one credit case.
         * @summary 
         * @param {CreateCreditCase} createCreditCase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesCreateOne(createCreditCase: CreateCreditCase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesCreateOne(createCreditCase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesDeleteOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesDeleteOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesFindById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindCreditCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesFindById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {ForwardCreditCase} forwardCreditCase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesForwardOne(creditCaseId: number, forwardCreditCase: ForwardCreditCase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesForwardOne(creditCaseId, forwardCreditCase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all credit cases.
         * @summary 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {string | null} [query] 
         * @param {CreditCasesQueryManyActionEnum} [action] 
         * @param {CreditCasesQueryManyStateEnum} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesQueryMany(limit?: number | null, offset?: number | null, query?: string | null, action?: CreditCasesQueryManyActionEnum, state?: CreditCasesQueryManyStateEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCaseConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesQueryMany(limit, offset, query, action, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {UpdateCreditCase} updateCreditCase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesUpdateOne(id: number, updateCreditCase: UpdateCreditCase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesUpdateOne(id, updateCreditCase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {CreditCasesUpdateStateStateEnum} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesUpdateState(creditCaseId: number, state: CreditCasesUpdateStateStateEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesUpdateState(creditCaseId, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update debtor info for active credit case.
         * @summary 
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {UpdateDebtor} updateDebtor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async debtorsUpdateOne(creditCaseId: number, updateDebtor: UpdateDebtor, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Debtor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.debtorsUpdateOne(creditCaseId, updateDebtor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add one invoice to the draft credit case.
         * @summary 
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {CreateInvoice} createInvoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesCreateOne(creditCaseId: number, createInvoice: CreateInvoice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesCreateOne(creditCaseId, createInvoice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one invoice of a draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesDeleteOne(id: number, creditCaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesDeleteOne(id, creditCaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesDownload(id: number, creditCaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesDownload(id, creditCaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one invoice of a draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {UpdateInvoice} updateInvoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesUpdateOne(id: number, creditCaseId: number, updateInvoice: UpdateInvoice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesUpdateOne(id, creditCaseId, updateInvoice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {number} creditCaseId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesUpload(id: number, creditCaseId: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesUpload(id, creditCaseId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add one message to credit case.
         * @summary 
         * @param {number} creditCaseId 
         * @param {CreateMessage} createMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesCreateOne(creditCaseId: number, createMessage: CreateMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesCreateOne(creditCaseId, createMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add one note to the credit case.
         * @summary 
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {CreateNote} createNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesCreateOne(creditCaseId: number, createNote: CreateNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesCreateOne(creditCaseId, createNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one note of a credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesDeleteOne(id: number, creditCaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesDeleteOne(id, creditCaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {CreatePaymentPlan} createPaymentPlan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanCreateOne(creditCaseId: number, createPaymentPlan: CreatePaymentPlan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanCreateOne(creditCaseId, createPaymentPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanDeleteOne(creditCaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanDeleteOne(creditCaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} creditCaseId 
         * @param {number} amount 
         * @param {number} terms 
         * @param {PaymentPlanSuggestOneTypeEnum} type 
         * @param {string} dueDate The date the payment plan starts on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanSuggestOne(creditCaseId: number, amount: number, terms: number, type: PaymentPlanSuggestOneTypeEnum, dueDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuggestedPlannedPayment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanSuggestOne(creditCaseId, amount, terms, type, dueDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add one payment to credit case.
         * @summary 
         * @param {number} creditCaseId 
         * @param {CreatePayment} createPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsCreateOne(creditCaseId: number, createPayment: CreatePayment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsCreateOne(creditCaseId, createPayment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one payment from a credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {number} creditCaseId ID of the credit case this invoice belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsDeleteOne(id: number, creditCaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsDeleteOne(id, creditCaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreditCasesApi - factory interface
 * @export
 */
export const CreditCasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreditCasesApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreditCasesApiCaseActionGetEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseActionGetEmail(requestParameters: CreditCasesApiCaseActionGetEmailRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.caseActionGetEmail(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiCaseActionGetLetterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseActionGetLetter(requestParameters: CreditCasesApiCaseActionGetLetterRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.caseActionGetLetter(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiCaseActionUpdateFailedReasonRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseActionUpdateFailedReason(requestParameters: CreditCasesApiCaseActionUpdateFailedReasonRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.caseActionUpdateFailedReason(requestParameters.id, requestParameters.creditCaseId, requestParameters.updateCaseActionFailedReason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiCaseAttachmentDeleteOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAttachmentDeleteOne(requestParameters: CreditCasesApiCaseAttachmentDeleteOneRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.caseAttachmentDeleteOne(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiCaseAttachmentDownloadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAttachmentDownload(requestParameters: CreditCasesApiCaseAttachmentDownloadRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.caseAttachmentDownload(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiCaseAttachmentUploadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAttachmentUpload(requestParameters: CreditCasesApiCaseAttachmentUploadRequest, options?: AxiosRequestConfig): AxiosPromise<CaseAttachment> {
            return localVarFp.caseAttachmentUpload(requestParameters.creditCaseId, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiCaseAttachmentUploadPreIncassoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAttachmentUploadPreIncasso(requestParameters: CreditCasesApiCaseAttachmentUploadPreIncassoRequest, options?: AxiosRequestConfig): AxiosPromise<CaseAttachment> {
            return localVarFp.caseAttachmentUploadPreIncasso(requestParameters.creditCaseId, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiCaseTimelineStepQueryManyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseTimelineStepQueryMany(requestParameters: CreditCasesApiCaseTimelineStepQueryManyRequest, options?: AxiosRequestConfig): AxiosPromise<CaseTimelineConnection> {
            return localVarFp.caseTimelineStepQueryMany(requestParameters.creditCaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create one credit case.
         * @summary 
         * @param {CreditCasesApiCreditCasesCreateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesCreateOne(requestParameters: CreditCasesApiCreditCasesCreateOneRequest, options?: AxiosRequestConfig): AxiosPromise<CreditCase> {
            return localVarFp.creditCasesCreateOne(requestParameters.createCreditCase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one draft credit case.
         * @summary 
         * @param {CreditCasesApiCreditCasesDeleteOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesDeleteOne(requestParameters: CreditCasesApiCreditCasesDeleteOneRequest, options?: AxiosRequestConfig): AxiosPromise<CreditCase> {
            return localVarFp.creditCasesDeleteOne(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one credit case.
         * @summary 
         * @param {CreditCasesApiCreditCasesFindByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesFindById(requestParameters: CreditCasesApiCreditCasesFindByIdRequest, options?: AxiosRequestConfig): AxiosPromise<FindCreditCase> {
            return localVarFp.creditCasesFindById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiCreditCasesForwardOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesForwardOne(requestParameters: CreditCasesApiCreditCasesForwardOneRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.creditCasesForwardOne(requestParameters.creditCaseId, requestParameters.forwardCreditCase, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all credit cases.
         * @summary 
         * @param {CreditCasesApiCreditCasesQueryManyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesQueryMany(requestParameters: CreditCasesApiCreditCasesQueryManyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreditCaseConnection> {
            return localVarFp.creditCasesQueryMany(requestParameters.limit, requestParameters.offset, requestParameters.query, requestParameters.action, requestParameters.state, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one draft credit case.
         * @summary 
         * @param {CreditCasesApiCreditCasesUpdateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesUpdateOne(requestParameters: CreditCasesApiCreditCasesUpdateOneRequest, options?: AxiosRequestConfig): AxiosPromise<CreditCase> {
            return localVarFp.creditCasesUpdateOne(requestParameters.id, requestParameters.updateCreditCase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiCreditCasesUpdateStateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesUpdateState(requestParameters: CreditCasesApiCreditCasesUpdateStateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.creditCasesUpdateState(requestParameters.creditCaseId, requestParameters.state, options).then((request) => request(axios, basePath));
        },
        /**
         * Update debtor info for active credit case.
         * @summary 
         * @param {CreditCasesApiDebtorsUpdateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debtorsUpdateOne(requestParameters: CreditCasesApiDebtorsUpdateOneRequest, options?: AxiosRequestConfig): AxiosPromise<Debtor> {
            return localVarFp.debtorsUpdateOne(requestParameters.creditCaseId, requestParameters.updateDebtor, options).then((request) => request(axios, basePath));
        },
        /**
         * Add one invoice to the draft credit case.
         * @summary 
         * @param {CreditCasesApiInvoicesCreateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesCreateOne(requestParameters: CreditCasesApiInvoicesCreateOneRequest, options?: AxiosRequestConfig): AxiosPromise<Invoice> {
            return localVarFp.invoicesCreateOne(requestParameters.creditCaseId, requestParameters.createInvoice, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one invoice of a draft credit case.
         * @summary 
         * @param {CreditCasesApiInvoicesDeleteOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesDeleteOne(requestParameters: CreditCasesApiInvoicesDeleteOneRequest, options?: AxiosRequestConfig): AxiosPromise<Invoice> {
            return localVarFp.invoicesDeleteOne(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiInvoicesDownloadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesDownload(requestParameters: CreditCasesApiInvoicesDownloadRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.invoicesDownload(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one invoice of a draft credit case.
         * @summary 
         * @param {CreditCasesApiInvoicesUpdateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesUpdateOne(requestParameters: CreditCasesApiInvoicesUpdateOneRequest, options?: AxiosRequestConfig): AxiosPromise<Invoice> {
            return localVarFp.invoicesUpdateOne(requestParameters.id, requestParameters.creditCaseId, requestParameters.updateInvoice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiInvoicesUploadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesUpload(requestParameters: CreditCasesApiInvoicesUploadRequest, options?: AxiosRequestConfig): AxiosPromise<Invoice> {
            return localVarFp.invoicesUpload(requestParameters.id, requestParameters.creditCaseId, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * Add one message to credit case.
         * @summary 
         * @param {CreditCasesApiMessagesCreateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesCreateOne(requestParameters: CreditCasesApiMessagesCreateOneRequest, options?: AxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.messagesCreateOne(requestParameters.creditCaseId, requestParameters.createMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * Add one note to the credit case.
         * @summary 
         * @param {CreditCasesApiNotesCreateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesCreateOne(requestParameters: CreditCasesApiNotesCreateOneRequest, options?: AxiosRequestConfig): AxiosPromise<Note> {
            return localVarFp.notesCreateOne(requestParameters.creditCaseId, requestParameters.createNote, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one note of a credit case.
         * @summary 
         * @param {CreditCasesApiNotesDeleteOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesDeleteOne(requestParameters: CreditCasesApiNotesDeleteOneRequest, options?: AxiosRequestConfig): AxiosPromise<Note> {
            return localVarFp.notesDeleteOne(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiPaymentPlanCreateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanCreateOne(requestParameters: CreditCasesApiPaymentPlanCreateOneRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.paymentPlanCreateOne(requestParameters.creditCaseId, requestParameters.createPaymentPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiPaymentPlanDeleteOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanDeleteOne(requestParameters: CreditCasesApiPaymentPlanDeleteOneRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.paymentPlanDeleteOne(requestParameters.creditCaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreditCasesApiPaymentPlanSuggestOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanSuggestOne(requestParameters: CreditCasesApiPaymentPlanSuggestOneRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SuggestedPlannedPayment>> {
            return localVarFp.paymentPlanSuggestOne(requestParameters.creditCaseId, requestParameters.amount, requestParameters.terms, requestParameters.type, requestParameters.dueDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Add one payment to credit case.
         * @summary 
         * @param {CreditCasesApiPaymentsCreateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCreateOne(requestParameters: CreditCasesApiPaymentsCreateOneRequest, options?: AxiosRequestConfig): AxiosPromise<Payment> {
            return localVarFp.paymentsCreateOne(requestParameters.creditCaseId, requestParameters.createPayment, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one payment from a credit case.
         * @summary 
         * @param {CreditCasesApiPaymentsDeleteOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsDeleteOne(requestParameters: CreditCasesApiPaymentsDeleteOneRequest, options?: AxiosRequestConfig): AxiosPromise<Payment> {
            return localVarFp.paymentsDeleteOne(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for caseActionGetEmail operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCaseActionGetEmailRequest
 */
export interface CreditCasesApiCaseActionGetEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseActionGetEmail
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseActionGetEmail
     */
    readonly creditCaseId: number
}

/**
 * Request parameters for caseActionGetLetter operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCaseActionGetLetterRequest
 */
export interface CreditCasesApiCaseActionGetLetterRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseActionGetLetter
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseActionGetLetter
     */
    readonly creditCaseId: number
}

/**
 * Request parameters for caseActionUpdateFailedReason operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCaseActionUpdateFailedReasonRequest
 */
export interface CreditCasesApiCaseActionUpdateFailedReasonRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseActionUpdateFailedReason
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseActionUpdateFailedReason
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {UpdateCaseActionFailedReason}
     * @memberof CreditCasesApiCaseActionUpdateFailedReason
     */
    readonly updateCaseActionFailedReason: UpdateCaseActionFailedReason
}

/**
 * Request parameters for caseAttachmentDeleteOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCaseAttachmentDeleteOneRequest
 */
export interface CreditCasesApiCaseAttachmentDeleteOneRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseAttachmentDeleteOne
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseAttachmentDeleteOne
     */
    readonly creditCaseId: number
}

/**
 * Request parameters for caseAttachmentDownload operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCaseAttachmentDownloadRequest
 */
export interface CreditCasesApiCaseAttachmentDownloadRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseAttachmentDownload
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseAttachmentDownload
     */
    readonly creditCaseId: number
}

/**
 * Request parameters for caseAttachmentUpload operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCaseAttachmentUploadRequest
 */
export interface CreditCasesApiCaseAttachmentUploadRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseAttachmentUpload
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {File}
     * @memberof CreditCasesApiCaseAttachmentUpload
     */
    readonly file?: File
}

/**
 * Request parameters for caseAttachmentUploadPreIncasso operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCaseAttachmentUploadPreIncassoRequest
 */
export interface CreditCasesApiCaseAttachmentUploadPreIncassoRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseAttachmentUploadPreIncasso
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {File}
     * @memberof CreditCasesApiCaseAttachmentUploadPreIncasso
     */
    readonly file?: File
}

/**
 * Request parameters for caseTimelineStepQueryMany operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCaseTimelineStepQueryManyRequest
 */
export interface CreditCasesApiCaseTimelineStepQueryManyRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCaseTimelineStepQueryMany
     */
    readonly creditCaseId: number
}

/**
 * Request parameters for creditCasesCreateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCreditCasesCreateOneRequest
 */
export interface CreditCasesApiCreditCasesCreateOneRequest {
    /**
     * 
     * @type {CreateCreditCase}
     * @memberof CreditCasesApiCreditCasesCreateOne
     */
    readonly createCreditCase: CreateCreditCase
}

/**
 * Request parameters for creditCasesDeleteOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCreditCasesDeleteOneRequest
 */
export interface CreditCasesApiCreditCasesDeleteOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCasesApiCreditCasesDeleteOne
     */
    readonly id: number
}

/**
 * Request parameters for creditCasesFindById operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCreditCasesFindByIdRequest
 */
export interface CreditCasesApiCreditCasesFindByIdRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCasesApiCreditCasesFindById
     */
    readonly id: number
}

/**
 * Request parameters for creditCasesForwardOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCreditCasesForwardOneRequest
 */
export interface CreditCasesApiCreditCasesForwardOneRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCreditCasesForwardOne
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {ForwardCreditCase}
     * @memberof CreditCasesApiCreditCasesForwardOne
     */
    readonly forwardCreditCase: ForwardCreditCase
}

/**
 * Request parameters for creditCasesQueryMany operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCreditCasesQueryManyRequest
 */
export interface CreditCasesApiCreditCasesQueryManyRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCreditCasesQueryMany
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCreditCasesQueryMany
     */
    readonly offset?: number | null

    /**
     * 
     * @type {string}
     * @memberof CreditCasesApiCreditCasesQueryMany
     */
    readonly query?: string | null

    /**
     * 
     * @type {'draft' | 'pre-debt-collection' | 'with-unread-messages' | 'due-payment' | 'paused' | 'finished' | 'judiciary'}
     * @memberof CreditCasesApiCreditCasesQueryMany
     */
    readonly action?: CreditCasesQueryManyActionEnum

    /**
     * 
     * @type {'draft' | 'paused' | 'forwarded' | 'closed' | 'finished' | 'paid' | 'open'}
     * @memberof CreditCasesApiCreditCasesQueryMany
     */
    readonly state?: CreditCasesQueryManyStateEnum
}

/**
 * Request parameters for creditCasesUpdateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCreditCasesUpdateOneRequest
 */
export interface CreditCasesApiCreditCasesUpdateOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCasesApiCreditCasesUpdateOne
     */
    readonly id: number

    /**
     * 
     * @type {UpdateCreditCase}
     * @memberof CreditCasesApiCreditCasesUpdateOne
     */
    readonly updateCreditCase: UpdateCreditCase
}

/**
 * Request parameters for creditCasesUpdateState operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCreditCasesUpdateStateRequest
 */
export interface CreditCasesApiCreditCasesUpdateStateRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCreditCasesUpdateState
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {'pause' | 'close' | 'reopen'}
     * @memberof CreditCasesApiCreditCasesUpdateState
     */
    readonly state: CreditCasesUpdateStateStateEnum
}

/**
 * Request parameters for debtorsUpdateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiDebtorsUpdateOneRequest
 */
export interface CreditCasesApiDebtorsUpdateOneRequest {
    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof CreditCasesApiDebtorsUpdateOne
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {UpdateDebtor}
     * @memberof CreditCasesApiDebtorsUpdateOne
     */
    readonly updateDebtor: UpdateDebtor
}

/**
 * Request parameters for invoicesCreateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiInvoicesCreateOneRequest
 */
export interface CreditCasesApiInvoicesCreateOneRequest {
    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof CreditCasesApiInvoicesCreateOne
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {CreateInvoice}
     * @memberof CreditCasesApiInvoicesCreateOne
     */
    readonly createInvoice: CreateInvoice
}

/**
 * Request parameters for invoicesDeleteOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiInvoicesDeleteOneRequest
 */
export interface CreditCasesApiInvoicesDeleteOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCasesApiInvoicesDeleteOne
     */
    readonly id: number

    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof CreditCasesApiInvoicesDeleteOne
     */
    readonly creditCaseId: number
}

/**
 * Request parameters for invoicesDownload operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiInvoicesDownloadRequest
 */
export interface CreditCasesApiInvoicesDownloadRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiInvoicesDownload
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiInvoicesDownload
     */
    readonly creditCaseId: number
}

/**
 * Request parameters for invoicesUpdateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiInvoicesUpdateOneRequest
 */
export interface CreditCasesApiInvoicesUpdateOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCasesApiInvoicesUpdateOne
     */
    readonly id: number

    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof CreditCasesApiInvoicesUpdateOne
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {UpdateInvoice}
     * @memberof CreditCasesApiInvoicesUpdateOne
     */
    readonly updateInvoice: UpdateInvoice
}

/**
 * Request parameters for invoicesUpload operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiInvoicesUploadRequest
 */
export interface CreditCasesApiInvoicesUploadRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiInvoicesUpload
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiInvoicesUpload
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {File}
     * @memberof CreditCasesApiInvoicesUpload
     */
    readonly file?: File
}

/**
 * Request parameters for messagesCreateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiMessagesCreateOneRequest
 */
export interface CreditCasesApiMessagesCreateOneRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiMessagesCreateOne
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {CreateMessage}
     * @memberof CreditCasesApiMessagesCreateOne
     */
    readonly createMessage: CreateMessage
}

/**
 * Request parameters for notesCreateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiNotesCreateOneRequest
 */
export interface CreditCasesApiNotesCreateOneRequest {
    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof CreditCasesApiNotesCreateOne
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {CreateNote}
     * @memberof CreditCasesApiNotesCreateOne
     */
    readonly createNote: CreateNote
}

/**
 * Request parameters for notesDeleteOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiNotesDeleteOneRequest
 */
export interface CreditCasesApiNotesDeleteOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCasesApiNotesDeleteOne
     */
    readonly id: number

    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof CreditCasesApiNotesDeleteOne
     */
    readonly creditCaseId: number
}

/**
 * Request parameters for paymentPlanCreateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiPaymentPlanCreateOneRequest
 */
export interface CreditCasesApiPaymentPlanCreateOneRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiPaymentPlanCreateOne
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {CreatePaymentPlan}
     * @memberof CreditCasesApiPaymentPlanCreateOne
     */
    readonly createPaymentPlan: CreatePaymentPlan
}

/**
 * Request parameters for paymentPlanDeleteOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiPaymentPlanDeleteOneRequest
 */
export interface CreditCasesApiPaymentPlanDeleteOneRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiPaymentPlanDeleteOne
     */
    readonly creditCaseId: number
}

/**
 * Request parameters for paymentPlanSuggestOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiPaymentPlanSuggestOneRequest
 */
export interface CreditCasesApiPaymentPlanSuggestOneRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiPaymentPlanSuggestOne
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiPaymentPlanSuggestOne
     */
    readonly amount: number

    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiPaymentPlanSuggestOne
     */
    readonly terms: number

    /**
     * 
     * @type {'commitment' | 'monthly' | 'weekly' | 'custom'}
     * @memberof CreditCasesApiPaymentPlanSuggestOne
     */
    readonly type: PaymentPlanSuggestOneTypeEnum

    /**
     * The date the payment plan starts on.
     * @type {string}
     * @memberof CreditCasesApiPaymentPlanSuggestOne
     */
    readonly dueDate: string
}

/**
 * Request parameters for paymentsCreateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiPaymentsCreateOneRequest
 */
export interface CreditCasesApiPaymentsCreateOneRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiPaymentsCreateOne
     */
    readonly creditCaseId: number

    /**
     * 
     * @type {CreatePayment}
     * @memberof CreditCasesApiPaymentsCreateOne
     */
    readonly createPayment: CreatePayment
}

/**
 * Request parameters for paymentsDeleteOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiPaymentsDeleteOneRequest
 */
export interface CreditCasesApiPaymentsDeleteOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCasesApiPaymentsDeleteOne
     */
    readonly id: number

    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof CreditCasesApiPaymentsDeleteOne
     */
    readonly creditCaseId: number
}

/**
 * CreditCasesApi - object-oriented interface
 * @export
 * @class CreditCasesApi
 * @extends {BaseAPI}
 */
export class CreditCasesApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreditCasesApiCaseActionGetEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public caseActionGetEmail(requestParameters: CreditCasesApiCaseActionGetEmailRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).caseActionGetEmail(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiCaseActionGetLetterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public caseActionGetLetter(requestParameters: CreditCasesApiCaseActionGetLetterRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).caseActionGetLetter(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiCaseActionUpdateFailedReasonRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public caseActionUpdateFailedReason(requestParameters: CreditCasesApiCaseActionUpdateFailedReasonRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).caseActionUpdateFailedReason(requestParameters.id, requestParameters.creditCaseId, requestParameters.updateCaseActionFailedReason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiCaseAttachmentDeleteOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public caseAttachmentDeleteOne(requestParameters: CreditCasesApiCaseAttachmentDeleteOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).caseAttachmentDeleteOne(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiCaseAttachmentDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public caseAttachmentDownload(requestParameters: CreditCasesApiCaseAttachmentDownloadRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).caseAttachmentDownload(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiCaseAttachmentUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public caseAttachmentUpload(requestParameters: CreditCasesApiCaseAttachmentUploadRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).caseAttachmentUpload(requestParameters.creditCaseId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiCaseAttachmentUploadPreIncassoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public caseAttachmentUploadPreIncasso(requestParameters: CreditCasesApiCaseAttachmentUploadPreIncassoRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).caseAttachmentUploadPreIncasso(requestParameters.creditCaseId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiCaseTimelineStepQueryManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public caseTimelineStepQueryMany(requestParameters: CreditCasesApiCaseTimelineStepQueryManyRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).caseTimelineStepQueryMany(requestParameters.creditCaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create one credit case.
     * @summary 
     * @param {CreditCasesApiCreditCasesCreateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public creditCasesCreateOne(requestParameters: CreditCasesApiCreditCasesCreateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).creditCasesCreateOne(requestParameters.createCreditCase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one draft credit case.
     * @summary 
     * @param {CreditCasesApiCreditCasesDeleteOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public creditCasesDeleteOne(requestParameters: CreditCasesApiCreditCasesDeleteOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).creditCasesDeleteOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one credit case.
     * @summary 
     * @param {CreditCasesApiCreditCasesFindByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public creditCasesFindById(requestParameters: CreditCasesApiCreditCasesFindByIdRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).creditCasesFindById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiCreditCasesForwardOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public creditCasesForwardOne(requestParameters: CreditCasesApiCreditCasesForwardOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).creditCasesForwardOne(requestParameters.creditCaseId, requestParameters.forwardCreditCase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all credit cases.
     * @summary 
     * @param {CreditCasesApiCreditCasesQueryManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public creditCasesQueryMany(requestParameters: CreditCasesApiCreditCasesQueryManyRequest = {}, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).creditCasesQueryMany(requestParameters.limit, requestParameters.offset, requestParameters.query, requestParameters.action, requestParameters.state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one draft credit case.
     * @summary 
     * @param {CreditCasesApiCreditCasesUpdateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public creditCasesUpdateOne(requestParameters: CreditCasesApiCreditCasesUpdateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).creditCasesUpdateOne(requestParameters.id, requestParameters.updateCreditCase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiCreditCasesUpdateStateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public creditCasesUpdateState(requestParameters: CreditCasesApiCreditCasesUpdateStateRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).creditCasesUpdateState(requestParameters.creditCaseId, requestParameters.state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update debtor info for active credit case.
     * @summary 
     * @param {CreditCasesApiDebtorsUpdateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public debtorsUpdateOne(requestParameters: CreditCasesApiDebtorsUpdateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).debtorsUpdateOne(requestParameters.creditCaseId, requestParameters.updateDebtor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add one invoice to the draft credit case.
     * @summary 
     * @param {CreditCasesApiInvoicesCreateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public invoicesCreateOne(requestParameters: CreditCasesApiInvoicesCreateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).invoicesCreateOne(requestParameters.creditCaseId, requestParameters.createInvoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one invoice of a draft credit case.
     * @summary 
     * @param {CreditCasesApiInvoicesDeleteOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public invoicesDeleteOne(requestParameters: CreditCasesApiInvoicesDeleteOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).invoicesDeleteOne(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiInvoicesDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public invoicesDownload(requestParameters: CreditCasesApiInvoicesDownloadRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).invoicesDownload(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one invoice of a draft credit case.
     * @summary 
     * @param {CreditCasesApiInvoicesUpdateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public invoicesUpdateOne(requestParameters: CreditCasesApiInvoicesUpdateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).invoicesUpdateOne(requestParameters.id, requestParameters.creditCaseId, requestParameters.updateInvoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiInvoicesUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public invoicesUpload(requestParameters: CreditCasesApiInvoicesUploadRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).invoicesUpload(requestParameters.id, requestParameters.creditCaseId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add one message to credit case.
     * @summary 
     * @param {CreditCasesApiMessagesCreateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public messagesCreateOne(requestParameters: CreditCasesApiMessagesCreateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).messagesCreateOne(requestParameters.creditCaseId, requestParameters.createMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add one note to the credit case.
     * @summary 
     * @param {CreditCasesApiNotesCreateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public notesCreateOne(requestParameters: CreditCasesApiNotesCreateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).notesCreateOne(requestParameters.creditCaseId, requestParameters.createNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one note of a credit case.
     * @summary 
     * @param {CreditCasesApiNotesDeleteOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public notesDeleteOne(requestParameters: CreditCasesApiNotesDeleteOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).notesDeleteOne(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiPaymentPlanCreateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public paymentPlanCreateOne(requestParameters: CreditCasesApiPaymentPlanCreateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).paymentPlanCreateOne(requestParameters.creditCaseId, requestParameters.createPaymentPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiPaymentPlanDeleteOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public paymentPlanDeleteOne(requestParameters: CreditCasesApiPaymentPlanDeleteOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).paymentPlanDeleteOne(requestParameters.creditCaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreditCasesApiPaymentPlanSuggestOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public paymentPlanSuggestOne(requestParameters: CreditCasesApiPaymentPlanSuggestOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).paymentPlanSuggestOne(requestParameters.creditCaseId, requestParameters.amount, requestParameters.terms, requestParameters.type, requestParameters.dueDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add one payment to credit case.
     * @summary 
     * @param {CreditCasesApiPaymentsCreateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public paymentsCreateOne(requestParameters: CreditCasesApiPaymentsCreateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).paymentsCreateOne(requestParameters.creditCaseId, requestParameters.createPayment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one payment from a credit case.
     * @summary 
     * @param {CreditCasesApiPaymentsDeleteOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public paymentsDeleteOne(requestParameters: CreditCasesApiPaymentsDeleteOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).paymentsDeleteOne(requestParameters.id, requestParameters.creditCaseId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const CreditCasesQueryManyActionEnum = {
    Draft: 'draft',
    PreDebtCollection: 'pre-debt-collection',
    WithUnreadMessages: 'with-unread-messages',
    DuePayment: 'due-payment',
    Paused: 'paused',
    Finished: 'finished',
    Judiciary: 'judiciary'
} as const;
export type CreditCasesQueryManyActionEnum = typeof CreditCasesQueryManyActionEnum[keyof typeof CreditCasesQueryManyActionEnum];
/**
 * @export
 */
export const CreditCasesQueryManyStateEnum = {
    Draft: 'draft',
    Paused: 'paused',
    Forwarded: 'forwarded',
    Closed: 'closed',
    Finished: 'finished',
    Paid: 'paid',
    Open: 'open'
} as const;
export type CreditCasesQueryManyStateEnum = typeof CreditCasesQueryManyStateEnum[keyof typeof CreditCasesQueryManyStateEnum];
/**
 * @export
 */
export const CreditCasesUpdateStateStateEnum = {
    Pause: 'pause',
    Close: 'close',
    Reopen: 'reopen'
} as const;
export type CreditCasesUpdateStateStateEnum = typeof CreditCasesUpdateStateStateEnum[keyof typeof CreditCasesUpdateStateStateEnum];
/**
 * @export
 */
export const PaymentPlanSuggestOneTypeEnum = {
    Commitment: 'commitment',
    Monthly: 'monthly',
    Weekly: 'weekly',
    Custom: 'custom'
} as const;
export type PaymentPlanSuggestOneTypeEnum = typeof PaymentPlanSuggestOneTypeEnum[keyof typeof PaymentPlanSuggestOneTypeEnum];


/**
 * CreditChecksApi - axios parameter creator
 * @export
 */
export const CreditChecksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCheckSearch: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('creditCheckSearch', 'query', query)
            const localVarPath = `/v2.0/credit-checks/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one credit check.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditChecksFindById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('creditChecksFindById', 'id', id)
            const localVarPath = `/v2.0/credit-checks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all credit checks.
         * @summary 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditChecksQueryMany: async (limit?: number | null, offset?: number | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/credit-checks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditChecksApi - functional programming interface
 * @export
 */
export const CreditChecksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreditChecksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCheckSearch(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchCreditCheckCompanyOffsetConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCheckSearch(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one credit check.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditChecksFindById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditChecksFindById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all credit checks.
         * @summary 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditChecksQueryMany(limit?: number | null, offset?: number | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCheckConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditChecksQueryMany(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreditChecksApi - factory interface
 * @export
 */
export const CreditChecksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreditChecksApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreditChecksApiCreditCheckSearchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCheckSearch(requestParameters: CreditChecksApiCreditCheckSearchRequest, options?: AxiosRequestConfig): AxiosPromise<SearchCreditCheckCompanyOffsetConnection> {
            return localVarFp.creditCheckSearch(requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one credit check.
         * @summary 
         * @param {CreditChecksApiCreditChecksFindByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditChecksFindById(requestParameters: CreditChecksApiCreditChecksFindByIdRequest, options?: AxiosRequestConfig): AxiosPromise<CreditCheck> {
            return localVarFp.creditChecksFindById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all credit checks.
         * @summary 
         * @param {CreditChecksApiCreditChecksQueryManyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditChecksQueryMany(requestParameters: CreditChecksApiCreditChecksQueryManyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreditCheckConnection> {
            return localVarFp.creditChecksQueryMany(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for creditCheckSearch operation in CreditChecksApi.
 * @export
 * @interface CreditChecksApiCreditCheckSearchRequest
 */
export interface CreditChecksApiCreditCheckSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof CreditChecksApiCreditCheckSearch
     */
    readonly query: string
}

/**
 * Request parameters for creditChecksFindById operation in CreditChecksApi.
 * @export
 * @interface CreditChecksApiCreditChecksFindByIdRequest
 */
export interface CreditChecksApiCreditChecksFindByIdRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditChecksApiCreditChecksFindById
     */
    readonly id: number
}

/**
 * Request parameters for creditChecksQueryMany operation in CreditChecksApi.
 * @export
 * @interface CreditChecksApiCreditChecksQueryManyRequest
 */
export interface CreditChecksApiCreditChecksQueryManyRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditChecksApiCreditChecksQueryMany
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof CreditChecksApiCreditChecksQueryMany
     */
    readonly offset?: number | null
}

/**
 * CreditChecksApi - object-oriented interface
 * @export
 * @class CreditChecksApi
 * @extends {BaseAPI}
 */
export class CreditChecksApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreditChecksApiCreditCheckSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditChecksApi
     */
    public creditCheckSearch(requestParameters: CreditChecksApiCreditCheckSearchRequest, options?: AxiosRequestConfig) {
        return CreditChecksApiFp(this.configuration).creditCheckSearch(requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one credit check.
     * @summary 
     * @param {CreditChecksApiCreditChecksFindByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditChecksApi
     */
    public creditChecksFindById(requestParameters: CreditChecksApiCreditChecksFindByIdRequest, options?: AxiosRequestConfig) {
        return CreditChecksApiFp(this.configuration).creditChecksFindById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all credit checks.
     * @summary 
     * @param {CreditChecksApiCreditChecksQueryManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditChecksApi
     */
    public creditChecksQueryMany(requestParameters: CreditChecksApiCreditChecksQueryManyRequest = {}, options?: AxiosRequestConfig) {
        return CreditChecksApiFp(this.configuration).creditChecksQueryMany(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/dashboard/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stats(options?: AxiosRequestConfig): AxiosPromise<DashboardStats> {
            return localVarFp.stats(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public stats(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).stats(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DebtorApi - axios parameter creator
 * @export
 */
export const DebtorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCaseCaseAttachmentDownload: async (id: number, hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('creditCaseCaseAttachmentDownload', 'id', id)
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('creditCaseCaseAttachmentDownload', 'hash', hash)
            const localVarPath = `/v2.0/debtor/credit-cases/{hash}/case-attachment/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} hash 
         * @param {CreateDebtorMessage} createDebtorMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCaseMessageCreateOne: async (hash: string, createDebtorMessage: CreateDebtorMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('creditCaseMessageCreateOne', 'hash', hash)
            // verify required parameter 'createDebtorMessage' is not null or undefined
            assertParamExists('creditCaseMessageCreateOne', 'createDebtorMessage', createDebtorMessage)
            const localVarPath = `/v2.0/debtor/credit-cases/{hash}/messages`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDebtorMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesFindByHash: async (hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('creditCasesFindByHash', 'hash', hash)
            const localVarPath = `/v2.0/debtor/credit-cases/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {LoginWithPin} loginWithPin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithPin: async (loginWithPin: LoginWithPin, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginWithPin' is not null or undefined
            assertParamExists('loginWithPin', 'loginWithPin', loginWithPin)
            const localVarPath = `/v2.0/debtor/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginWithPin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DebtorApi - functional programming interface
 * @export
 */
export const DebtorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DebtorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCaseCaseAttachmentDownload(id: number, hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCaseCaseAttachmentDownload(id, hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} hash 
         * @param {CreateDebtorMessage} createDebtorMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCaseMessageCreateOne(hash: string, createDebtorMessage: CreateDebtorMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseTimelineStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCaseMessageCreateOne(hash, createDebtorMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesFindByHash(hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DebtorCreditCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesFindByHash(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {LoginWithPin} loginWithPin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithPin(loginWithPin: LoginWithPin, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithPin(loginWithPin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DebtorApi - factory interface
 * @export
 */
export const DebtorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DebtorApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {DebtorApiCreditCaseCaseAttachmentDownloadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCaseCaseAttachmentDownload(requestParameters: DebtorApiCreditCaseCaseAttachmentDownloadRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.creditCaseCaseAttachmentDownload(requestParameters.id, requestParameters.hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {DebtorApiCreditCaseMessageCreateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCaseMessageCreateOne(requestParameters: DebtorApiCreditCaseMessageCreateOneRequest, options?: AxiosRequestConfig): AxiosPromise<CaseTimelineStep> {
            return localVarFp.creditCaseMessageCreateOne(requestParameters.hash, requestParameters.createDebtorMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {DebtorApiCreditCasesFindByHashRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesFindByHash(requestParameters: DebtorApiCreditCasesFindByHashRequest, options?: AxiosRequestConfig): AxiosPromise<DebtorCreditCase> {
            return localVarFp.creditCasesFindByHash(requestParameters.hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {DebtorApiLoginWithPinRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithPin(requestParameters: DebtorApiLoginWithPinRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.loginWithPin(requestParameters.loginWithPin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for creditCaseCaseAttachmentDownload operation in DebtorApi.
 * @export
 * @interface DebtorApiCreditCaseCaseAttachmentDownloadRequest
 */
export interface DebtorApiCreditCaseCaseAttachmentDownloadRequest {
    /**
     * 
     * @type {number}
     * @memberof DebtorApiCreditCaseCaseAttachmentDownload
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof DebtorApiCreditCaseCaseAttachmentDownload
     */
    readonly hash: string
}

/**
 * Request parameters for creditCaseMessageCreateOne operation in DebtorApi.
 * @export
 * @interface DebtorApiCreditCaseMessageCreateOneRequest
 */
export interface DebtorApiCreditCaseMessageCreateOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DebtorApiCreditCaseMessageCreateOne
     */
    readonly hash: string

    /**
     * 
     * @type {CreateDebtorMessage}
     * @memberof DebtorApiCreditCaseMessageCreateOne
     */
    readonly createDebtorMessage: CreateDebtorMessage
}

/**
 * Request parameters for creditCasesFindByHash operation in DebtorApi.
 * @export
 * @interface DebtorApiCreditCasesFindByHashRequest
 */
export interface DebtorApiCreditCasesFindByHashRequest {
    /**
     * 
     * @type {string}
     * @memberof DebtorApiCreditCasesFindByHash
     */
    readonly hash: string
}

/**
 * Request parameters for loginWithPin operation in DebtorApi.
 * @export
 * @interface DebtorApiLoginWithPinRequest
 */
export interface DebtorApiLoginWithPinRequest {
    /**
     * 
     * @type {LoginWithPin}
     * @memberof DebtorApiLoginWithPin
     */
    readonly loginWithPin: LoginWithPin
}

/**
 * DebtorApi - object-oriented interface
 * @export
 * @class DebtorApi
 * @extends {BaseAPI}
 */
export class DebtorApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {DebtorApiCreditCaseCaseAttachmentDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtorApi
     */
    public creditCaseCaseAttachmentDownload(requestParameters: DebtorApiCreditCaseCaseAttachmentDownloadRequest, options?: AxiosRequestConfig) {
        return DebtorApiFp(this.configuration).creditCaseCaseAttachmentDownload(requestParameters.id, requestParameters.hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {DebtorApiCreditCaseMessageCreateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtorApi
     */
    public creditCaseMessageCreateOne(requestParameters: DebtorApiCreditCaseMessageCreateOneRequest, options?: AxiosRequestConfig) {
        return DebtorApiFp(this.configuration).creditCaseMessageCreateOne(requestParameters.hash, requestParameters.createDebtorMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {DebtorApiCreditCasesFindByHashRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtorApi
     */
    public creditCasesFindByHash(requestParameters: DebtorApiCreditCasesFindByHashRequest, options?: AxiosRequestConfig) {
        return DebtorApiFp(this.configuration).creditCasesFindByHash(requestParameters.hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {DebtorApiLoginWithPinRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtorApi
     */
    public loginWithPin(requestParameters: DebtorApiLoginWithPinRequest, options?: AxiosRequestConfig) {
        return DebtorApiFp(this.configuration).loginWithPin(requestParameters.loginWithPin, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OAuthApi - axios parameter creator
 * @export
 */
export const OAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} oauthToken 
         * @param {Authorize} authorize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize: async (oauthToken: string, authorize: Authorize, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oauthToken' is not null or undefined
            assertParamExists('authorize', 'oauthToken', oauthToken)
            // verify required parameter 'authorize' is not null or undefined
            assertParamExists('authorize', 'authorize', authorize)
            const localVarPath = `/v2.0/oauth/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (oauthToken !== undefined) {
                localVarQueryParameter['oauthToken'] = oauthToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorize, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (login: Login, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('login', 'login', login)
            const localVarPath = `/v2.0/oauth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/oauth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/oauth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} oauthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAuthorize: async (oauthToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oauthToken' is not null or undefined
            assertParamExists('validateAuthorize', 'oauthToken', oauthToken)
            const localVarPath = `/v2.0/oauth/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (oauthToken !== undefined) {
                localVarQueryParameter['oauthToken'] = oauthToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OAuthApi - functional programming interface
 * @export
 */
export const OAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} oauthToken 
         * @param {Authorize} authorize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorize(oauthToken: string, authorize: Authorize, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizeRedirect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorize(oauthToken, authorize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(login: Login, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async me(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Me>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.me(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} oauthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateAuthorize(oauthToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateAuthorize(oauthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OAuthApi - factory interface
 * @export
 */
export const OAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {OAuthApiAuthorizeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize(requestParameters: OAuthApiAuthorizeRequest, options?: AxiosRequestConfig): AxiosPromise<AuthorizeRedirect> {
            return localVarFp.authorize(requestParameters.oauthToken, requestParameters.authorize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {OAuthApiLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParameters: OAuthApiLoginRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.login(requestParameters.login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(options?: AxiosRequestConfig): AxiosPromise<Me> {
            return localVarFp.me(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {OAuthApiValidateAuthorizeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAuthorize(requestParameters: OAuthApiValidateAuthorizeRequest, options?: AxiosRequestConfig): AxiosPromise<ClientApplication> {
            return localVarFp.validateAuthorize(requestParameters.oauthToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authorize operation in OAuthApi.
 * @export
 * @interface OAuthApiAuthorizeRequest
 */
export interface OAuthApiAuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof OAuthApiAuthorize
     */
    readonly oauthToken: string

    /**
     * 
     * @type {Authorize}
     * @memberof OAuthApiAuthorize
     */
    readonly authorize: Authorize
}

/**
 * Request parameters for login operation in OAuthApi.
 * @export
 * @interface OAuthApiLoginRequest
 */
export interface OAuthApiLoginRequest {
    /**
     * 
     * @type {Login}
     * @memberof OAuthApiLogin
     */
    readonly login: Login
}

/**
 * Request parameters for validateAuthorize operation in OAuthApi.
 * @export
 * @interface OAuthApiValidateAuthorizeRequest
 */
export interface OAuthApiValidateAuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof OAuthApiValidateAuthorize
     */
    readonly oauthToken: string
}

/**
 * OAuthApi - object-oriented interface
 * @export
 * @class OAuthApi
 * @extends {BaseAPI}
 */
export class OAuthApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {OAuthApiAuthorizeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public authorize(requestParameters: OAuthApiAuthorizeRequest, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).authorize(requestParameters.oauthToken, requestParameters.authorize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {OAuthApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public login(requestParameters: OAuthApiLoginRequest, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).login(requestParameters.login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public logout(options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public me(options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).me(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {OAuthApiValidateAuthorizeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public validateAuthorize(requestParameters: OAuthApiValidateAuthorizeRequest, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).validateAuthorize(requestParameters.oauthToken, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {UpdateMe} updateMe 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meUpdate: async (updateMe: UpdateMe, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMe' is not null or undefined
            assertParamExists('meUpdate', 'updateMe', updateMe)
            const localVarPath = `/v2.0/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMe, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ForgotPassword} forgotPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userForgotPassword: async (forgotPassword: ForgotPassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPassword' is not null or undefined
            assertParamExists('userForgotPassword', 'forgotPassword', forgotPassword)
            const localVarPath = `/v2.0/user/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {Register} register 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegister: async (register: Register, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'register' is not null or undefined
            assertParamExists('userRegister', 'register', register)
            const localVarPath = `/v2.0/user/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(register, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword: async (resetPassword: ResetPassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPassword' is not null or undefined
            assertParamExists('userResetPassword', 'resetPassword', resetPassword)
            const localVarPath = `/v2.0/user/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {UpdateMe} updateMe 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meUpdate(updateMe: UpdateMe, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateMe>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meUpdate(updateMe, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ForgotPassword} forgotPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userForgotPassword(forgotPassword: ForgotPassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userForgotPassword(forgotPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {Register} register 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRegister(register: Register, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRegister(register, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userResetPassword(resetPassword: ResetPassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userResetPassword(resetPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {UserApiMeUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meUpdate(requestParameters: UserApiMeUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<UpdateMe> {
            return localVarFp.meUpdate(requestParameters.updateMe, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UserApiUserForgotPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userForgotPassword(requestParameters: UserApiUserForgotPasswordRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userForgotPassword(requestParameters.forgotPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UserApiUserRegisterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegister(requestParameters: UserApiUserRegisterRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userRegister(requestParameters.register, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UserApiUserResetPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword(requestParameters: UserApiUserResetPasswordRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userResetPassword(requestParameters.resetPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for meUpdate operation in UserApi.
 * @export
 * @interface UserApiMeUpdateRequest
 */
export interface UserApiMeUpdateRequest {
    /**
     * 
     * @type {UpdateMe}
     * @memberof UserApiMeUpdate
     */
    readonly updateMe: UpdateMe
}

/**
 * Request parameters for userForgotPassword operation in UserApi.
 * @export
 * @interface UserApiUserForgotPasswordRequest
 */
export interface UserApiUserForgotPasswordRequest {
    /**
     * 
     * @type {ForgotPassword}
     * @memberof UserApiUserForgotPassword
     */
    readonly forgotPassword: ForgotPassword
}

/**
 * Request parameters for userRegister operation in UserApi.
 * @export
 * @interface UserApiUserRegisterRequest
 */
export interface UserApiUserRegisterRequest {
    /**
     * 
     * @type {Register}
     * @memberof UserApiUserRegister
     */
    readonly register: Register
}

/**
 * Request parameters for userResetPassword operation in UserApi.
 * @export
 * @interface UserApiUserResetPasswordRequest
 */
export interface UserApiUserResetPasswordRequest {
    /**
     * 
     * @type {ResetPassword}
     * @memberof UserApiUserResetPassword
     */
    readonly resetPassword: ResetPassword
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {UserApiMeUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public meUpdate(requestParameters: UserApiMeUpdateRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).meUpdate(requestParameters.updateMe, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UserApiUserForgotPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userForgotPassword(requestParameters: UserApiUserForgotPasswordRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userForgotPassword(requestParameters.forgotPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UserApiUserRegisterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRegister(requestParameters: UserApiUserRegisterRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userRegister(requestParameters.register, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UserApiUserResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userResetPassword(requestParameters: UserApiUserResetPasswordRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userResetPassword(requestParameters.resetPassword, options).then((request) => request(this.axios, this.basePath));
    }
}



